import React from 'react';
import { Icon, Table, Popup } from 'semantic-ui-react'
import { formatDecimals } from '../lib/format';
import { HelpPopup } from './HelpText'
import { observer } from 'mobx-react'
import { useStores } from '../Stores/use-stores';




const DeadReckoningDetails = ({rollPitchYaw, position, positionStd, velocity, velocityStd}) => (

    <Table unstackable columns={5} basic="very" style={{paddingTop: "1em", paddingLeft: "2em", paddingRight: "2em"}}>
    <Table.Body>

    <Table.Row textAlign="right">
        <Table.Cell colSpan={2}>
            X
        </Table.Cell>
        <Table.Cell>Y
        </Table.Cell>
        <Table.Cell >Down
        </Table.Cell>
        <Table.Cell colSpan={2} textAlign={"center"}>
        FOM <HelpPopup text={"(Figure of merit) A measure of possible error in the calculated speeds/positions"}/>
        </Table.Cell>
    </Table.Row>

    <Table.Row textAlign="right">
        <Table.Cell textAlign="left">
        Speed <HelpPopup text={"Calculated speed of movement in the X, Y and downward directions"}/>
        </Table.Cell>
        <Table.Cell textAlign="right">
        <strong> {formatDecimals(velocity[0], 3)} </strong>
        </Table.Cell>
        <Table.Cell textAlign="right">
        <strong> {formatDecimals(velocity[1], 3)} </strong>
        </Table.Cell>
        <Table.Cell textAlign="right">
        <strong> {formatDecimals(velocity[2], 3)} </strong>
        </Table.Cell>
        <Table.Cell textAlign="right">
            <strong> {formatDecimals(velocityStd, 3)}</strong>
        </Table.Cell>
        <Table.Cell textAlign="left" style={{paddingLeft:0}}>
        m/s
        </Table.Cell>
    </Table.Row>

    <Table.Row textAlign="right">
        <Table.Cell textAlign="left">
        Position <HelpPopup text={"Calculated positions in the X, Y, and downward directions"}/>
        </Table.Cell>
        <Table.Cell textAlign="right">
        <strong> {formatDecimals(position[0], position[0] >= 100 && position[0] < -100? 0: 3)} </strong>
        </Table.Cell>
        <Table.Cell textAlign="right">
        <strong> {formatDecimals(position[1], position[1] >= 100 && position[1] < -100? 0: 3)} </strong>
        </Table.Cell>
        <Table.Cell textAlign="right">
        <strong> {formatDecimals(position[2], position[2] >= 100 && position[2] < -100? 0: 3)} </strong>
        </Table.Cell>
        <Table.Cell>
            <strong> {formatDecimals(positionStd, positionStd >= 100 && positionStd < -100? 0: 3)}</strong>
        </Table.Cell>
        <Table.Cell textAlign="left" style={{paddingLeft:0}}>
        m
        </Table.Cell>
    </Table.Row>

    <Table.Row textAlign="right">
        <Table.Cell colSpan={6}>&nbsp;</Table.Cell>
    </Table.Row>


    <Table.Row textAlign="right">
        <Table.Cell singleLine textAlign="left" style={{border: "none"}}>
        Roll, pitch and yaw <Popup trigger={<Icon name="question circle outline"/>}>
            {/* <Popup.Header>Attitude and heading reference system</Popup.Header> */}
            {/* <Popup.Header>Orientation</Popup.Header> */}
            <Popup.Content>
            Orientation (from gyro)
            </Popup.Content>
        </Popup>
        </Table.Cell>
        <Table.Cell textAlign="right" style={{border: "none"}}>
        <strong> {formatDecimals(rollPitchYaw[0], 1)} </strong>
        </Table.Cell>
        <Table.Cell textAlign="right" style={{border: "none"}}>
        <strong> {formatDecimals(rollPitchYaw[1], 1)} </strong>
        </Table.Cell>
        <Table.Cell textAlign="right" style={{border: "none"}}>
        <strong> {formatDecimals(rollPitchYaw[2], 1)} </strong>
        </Table.Cell>
        <Table.Cell textAlign="left" colSpan={2} style={{paddingLeft:0, border: "none"}}>
        &deg;
        </Table.Cell>
    </Table.Row>



    </Table.Body>
    </Table>
)


const DeadReckoningStatus =  observer(() => {
     const { fusionStore }  = useStores()
     const { positionLocal, positionLocalStd, velocity, velocityStd, rollPitchYaw } = fusionStore

    return (
        <div>
        <DeadReckoningDetails rollPitchYaw={rollPitchYaw} position={positionLocal} positionStd={positionLocalStd} velocity={velocity} velocityStd={velocityStd}/>
    </div>
    )
})




export default DeadReckoningStatus
