import React, { useState } from 'react';
import { Button, Header } from 'semantic-ui-react';
import fetch from 'unfetch';
import { fetcher } from '../lib/fetch';
import useSWR, { mutate } from 'swr';
import { HelpPopup } from './HelpText';

const forceAdvancedUrl = '/api/force/advanced';

const shallowerDistance = [0.05, 0.3, 1.54, 7.68, 15.36];
const deeperDisance = [0.58, 3.07, 14.4, 36.0, "max"];

const shallowestToDistance = (mode) => {
    return shallowerDistance[mode] ?? '-';
};

const deeperstToDistance = (mode) => {
    return deeperDisance[mode] ?? '-';
};

function ShowRangeModeURIIfNotAuto() {
    const { data, error } = useSWR(forceAdvancedUrl, fetcher, { refreshInterval: 5000, dedupingInterval: 100 });
    if (error) {
        console.warn("Unable to fetch range mode")
        return null
    }
    if (!data) {
        return null
    }

    if (data.URI === "auto") {
        return ""
    }
    const range = shallowestToDistance(data.shallowest) + " to " + deeperstToDistance(data.deepest) + " m"
    return (
        <span>({data.URI} <HelpPopup text={"Forced range mode is configured. Searching for bottom lock in altitude from "+range} />)</span>
    );
}

function ForceRangeModeSetting() {
    const { data, error } = useSWR(forceAdvancedUrl, fetcher, { refreshInterval: 5000, dedupingInterval: 100 });
    if (error) {
        return <p>Error loading range mode</p>;
    }
    if (!data) {
        return <p>No data</p>;
    }
    return <ForceRangeModeSettingInner shallowest={data.shallowest} deepest={data.deepest} />;
}

async function PostForceRangeMode(lowerMode, upperMode) {
    const data = {
        shallowest: lowerMode,
        deepest: upperMode,
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    };
    console.log('sumbit', data);
    return fetch(forceAdvancedUrl, options);
}

function ForceRangeModeSettingInner({ shallowest, deepest }) {
    const [submitMessage, setSubmitMessage] = useState('');
    const [lowerMode, setLowerMode] = useState(shallowest);
    const [upperMode, setUpperMode] = useState(deepest);

    const change = (mode) => {
        if (mode === -1) {
            // Auto
            setLowerMode(-1);
            setUpperMode(-1);
        } else if (lowerMode === -1 || upperMode === -1) {
            // Reset from auto to manual
            setLowerMode(mode);
            setUpperMode(mode);
        }
        if (mode < lowerMode) {
            setLowerMode(mode);
        } else if (mode > upperMode) {
            setUpperMode(mode);
        } else {
            setLowerMode(mode);
            setUpperMode(mode);
        }
    };

    const submit = () => {
        setSubmitMessage('');
        PostForceRangeMode(lowerMode, upperMode)
            .then((r) => {
                if (!r.ok) {
                    throw new Error('Unsuccessful' + r.status);
                }
                return r.text();
            })
            .then((r) => {
                setSubmitMessage('Forced mode: ' + r);
                mutate(forceAdvancedUrl);
            })
            .catch((e) => {
                console.log('Error', e);
                setSubmitMessage('Set error: ' + e.toString());
            });
    };


    return (
        <div>
            <Header>Force range mode</Header>

            <div>
                <Button
                    content="Auto"
                    color={lowerMode === -1 ? 'green' : null}
                    icon="play"
                    onClick={() => change(-1)}
                />

                <Button
                    content="Range 0"
                    color={0 <= upperMode && 0 >= lowerMode ? 'green' : null}
                    onClick={() => change(0)}
                />
                <Button
                    content="Range 1"
                    color={1 <= upperMode && 1 >= lowerMode ? 'green' : null}
                    onClick={() => change(1)}
                />
                <Button
                    content="Range 2"
                    color={2 <= upperMode && 2 >= lowerMode ? 'green' : null}
                    onClick={() => change(2)}
                />
                <Button
                    content="Range 3"
                    color={3 <= upperMode && 3 >= lowerMode ? 'green' : null}
                    onClick={() => change(3)}
                />
                <Button
                    content="Range 4"
                    color={4 <= upperMode && 4 >= lowerMode ? 'green' : null}
                    onClick={() => change(4)}
                />
            </div>
            <div className="top-padding">
                Range <strong>{shallowestToDistance(lowerMode)}</strong> m to{' '}
                <strong>{deeperstToDistance(upperMode)}</strong> m
            </div>
            <div className="top-padding">
                <Button content="Apply" active onClick={() => submit()} />
            </div>
            <div className="top-padding">{submitMessage}</div>
        </div>
    );
}

export { ShowRangeModeURIIfNotAuto, ForceRangeModeSetting };
