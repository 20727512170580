function wsUrl() {
    const proto = (window.location.protocol === "https:") ? "wss" : "ws"
    if (process.env.REACT_APP_DEVMODE === "true") {
        // Somehow the websocket proxy in setupProxy.js is not working.
        // This workaround is needed until that is fixed.
        //console.log("DEVELOPMENT", proto, window.location.host)
        return proto + "://127.0.0.1:8080/ws"
        //return `${proto}://10.11.12.119:8080/ws`
        //return `${proto}://${window.location.host}/ws`


    }
    return `${proto}://${window.location.host}/ws`
}

export default wsUrl

// var connection = new WebSocket(
//     url.format({
//       protocol: window.location.protocol === 'https:' ? 'wss' : 'ws',
//       hostname: process.env.WDS_SOCKET_HOST || window.location.hostname,
//       port: process.env.WDS_SOCKET_PORT || window.location.port,
//       // Hardcoded in WebpackDevServer
//       pathname: process.env.WDS_SOCKET_PATH || '/sockjs-node',
//       slashes: true,
//     })
//   );
// WDS_SOCKET_HOST=10.11.12.127 WDS_SOCKET_PORT=80 WDS_SOCKET_PATH=ws npm run start
