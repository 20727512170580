import React, { Component, Fragment } from 'react';
import { Segment, Header, Message, Form, Table } from 'semantic-ui-react';
import { observer, inject } from 'mobx-react';
import { FormSetupError, FormIsLoading, SimpleFormInput, Configurator } from '../Components/FormStore';
import ApplyFeedbackButton from '../Components/ApplyFeedbackButton';
import { fetcher } from '../lib/fetch';
import useSWR from 'swr';

// Simplify setting preset options for the GenericFormStore
function remap(value, desc) {
    return { value: value, description: desc };
}

const NetworkConfigFormView = observer(({ form, onChange }) => (
    <Form>
        <Form.Group widths="equal">
            <SimpleFormInput
                fluid
                label="IP Address"
                value={form.fields.address.value}
                error={form.fields.address.error}
                name="address"
                onChange={onChange}
            />
            <SimpleFormInput
                fluid
                label="Subnet prefix (typically 24)"
                value={form.fields.prefix.value}
                error={form.fields.prefix.error}
                name="prefix"
                onChange={onChange}
            />
            <SimpleFormInput
                fluid
                label="Gateway"
                value={form.fields.gateway.value}
                error={form.fields.gateway.error}
                name="gateway"
                onChange={onChange}
            />
            <SimpleFormInput
                fluid
                label="DNS"
                value={form.fields.dns.value}
                error={form.fields.dns.error}
                name="dns"
                onChange={onChange}
            />
        </Form.Group>
    </Form>
));

const NetworkConfig = inject('networkConfigStore')(
    observer(
        class NetworkForm extends Component {
            componentDidMount() {
                this.props.networkConfigStore.fetch();
            }
            onChange = (key, val) => {
                console.log('Change ', key, val);
                this.props.networkConfigStore.formStore.form.fields.dhcp.value = val;
                this.props.networkConfigStore.storeError = ''; // Reset error on change
            };

            render() {
                const networkConfigStore = this.props.networkConfigStore;
                if (networkConfigStore.isLoading) {
                    return <FormIsLoading />;
                }
                if (!networkConfigStore.loadSuccess) {
                    return <FormSetupError header="Woops!">Error getting network configuration.</FormSetupError>;
                }
                let form = networkConfigStore.formStore.form;
                const onChangeStore = networkConfigStore.formStore.onFieldChange;
                return (
                    <div>
                        <Configurator
                            heading="Static or dynamic IP"
                            name="dhcp"
                            help={
                                "Select whether to equip the DVL with a static IP address, or whether the DVL should be assigned an IP address by means of DHCP.\n\nIf static, provide the IP address which you wish the DVL to have, as well as the subnet to which you wish it to belong. If you will only be using the DVL on a local network, the 'Gateway' and 'DNS' fields can be ignored; otherwise, 'Gateway' should typically be the IP address of a router in the same subnet as the DVL, and 'DNS' should be the IP address of a DNS server, such as 8.8.8.8"
                            }
                            value={form.fields.dhcp.value}
                            error={form.fields.dhcp.error}
                            options={[remap('true', 'DHCP Client'), remap('false', 'Static')]}
                            onChange={this.onChange}
                        />

                        {form.fields.dhcp.value === 'false' && (
                            <div>
                                <br />
                                <NetworkConfigFormView form={form} onChange={onChangeStore} />
                            </div>
                        )}
                        <br />
                        <ApplyFeedbackButton
                            type="submit"
                            onClick={() => {
                                networkConfigStore.store();
                            }}
                            disabled={!form.meta.isValid || networkConfigStore.isStoring}
                            isStoring={networkConfigStore.isStoring}
                            storeSuccess={networkConfigStore.storeSuccess}
                            storeFailure={networkConfigStore.storeSuccess ? '' : networkConfigStore.storeError}
                        />
                        {networkConfigStore.storeSuccess && (
                            <Message
                                info
                                icon="info circle"
                                content="You need to reboot the system for the IP configuration to take affect."
                            />
                        )}
                    </div>
                );
            }
        }
    )
);

const CurrentNetwork = () => {
    const { data, error } = useSWR('/api/v1/ip/current', fetcher);
    if (error) {
        return <p>Error loading status</p>;
    }
    if (!data) {
        return <p>Loading..</p>;
    }

    const entries = (data) ? (data) : []
    entries.sort((a, b) => a.address.localeCompare(b.address))
    return (
        <>
            <Header as="h3">Network status</Header>
            <Table basic="very" collapsing>
                <Table.Body>
                    {entries.map((entry) => (
                        <Fragment key={entry.address}>
                            <Table.Row>
                                <Table.Cell>IP address</Table.Cell>
                                <Table.Cell textAlign="right">{entry.address}</Table.Cell>
                            </Table.Row>
{/*
                            <Table.Row>
                                <Table.Cell>Subnet Prefix</Table.Cell>
                                <Table.Cell textAlign="right">
                                    <strong>{entry.prefix}</strong>
                                </Table.Cell>
                            </Table.Row>
                    */}
                        </Fragment>
                    ))}
                </Table.Body>
            </Table>
        </>
    );
};

const Network = () => (
    <Segment>
        <Header as="h3">Network configuration</Header>
        <Segment attached>
            <NetworkConfig />
        </Segment>
        <Segment attached>
            <CurrentNetwork />
        </Segment>
    </Segment>
);

export default Network;
