import React from 'react';
import { PropTypes } from 'prop-types';
import { Polyline } from 'react-leaflet'
import { latLng } from 'leaflet'

// Convert XY values to Leaflet map CRS coordinate system coordinates
function xyToLeflet(x, y) {
    return latLng(x, y)
}

function defaultGrid() {
    const maxRange = 500
    return { x: { min: -maxRange, max: maxRange}, y: { min: -maxRange, max: maxRange }}
}

// Generating grid based on grid settings
function generateGrid(gridSize, gridRange){
    const grid = []
    const delta = gridSize

    gridRange = gridRange? gridRange :defaultGrid()

    if((gridRange.y.max - gridRange.y.min)/delta > 500 || (gridRange.x.max - gridRange.x.min)/delta > 500) {
        return grid;
    }


    // Positive y
    for (let y = 0; y <= gridRange.y.max; y += delta) {
        grid.push([[gridRange.x.min, y], [gridRange.x.max, y]])
    }
    // Negative y
    for (let y = 0; y >= gridRange.y.min; y -= delta) {
        grid.push([[gridRange.x.min, y], [gridRange.x.max, y]])
    }

    // Positive x
    for (let x = 0; x <= gridRange.x.max; x += delta) {
        grid.push([[x, gridRange.y.min], [x, gridRange.y.max]])
    }
    // Negative x
    for (let x = 0; x >= gridRange.x.min; x -= delta) {
        grid.push([[x, gridRange.y.min], [x, gridRange.y.max]])
    }
    return grid
}

const AcousticGrid = ({gridSize, range, zIndex}) =>  {
    const getGrid = () => {
        const generated =  generateGrid(gridSize, range)

        return generated
    }
    const transform = (grid) => {
        const map = grid.map(d => d.map(inner => xyToLeflet(inner[0], inner[1])))

        return map
    }
    if(!gridSize) {
        return null;
    }
    const grid = getGrid()
    return ( 
        <Grid grid={transform(grid)}/>
        
    
    )
}


// AcousticGrid.propTypes = {
//     fullGrid: PropTypes.bool,
//     gridSize: PropTypes.number.isRequired,
//     range: PropTypes.shape({
//         x: PropTypes.shape({min: PropTypes.number.isRequired, max: PropTypes.number.isRequired}),
//         y: PropTypes.shape({min: PropTypes.number.isRequired, max: PropTypes.number.isRequired})
//     })
// }

const Grid = ({grid}) => {
    return (
        grid.length > 0 && grid.map((d, idx) => 
            <Polyline key={idx} positions={d} color="#eee" opacity="1" weight="1" />
        )
    )
}


Grid.propTypes = {
    gridSize: PropTypes.number,
    gridRange: PropTypes.shape({
        x: PropTypes.shape({min: PropTypes.number.isRequired, max: PropTypes.number.isRequired}),
        y: PropTypes.shape({min: PropTypes.number.isRequired, max: PropTypes.number.isRequired})
    })
}

generateGrid.propTypes = {
    gridSize: PropTypes.number,
    gridRange: PropTypes.shape({
        x: PropTypes.shape({min: PropTypes.number.isRequired, max: PropTypes.number.isRequired}),
        y: PropTypes.shape({min: PropTypes.number.isRequired, max: PropTypes.number.isRequired})
    })
}

export { AcousticGrid }
