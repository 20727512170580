import React from 'react';
import VelocityStore from './Velocity';
import FusionStore from './Fusion';
import OrientationStore from './Orientation';
import AboutInfoStore from './Version';
import TrailStore from './Trail';
import WarningMessageStore from './WarningMessagesStore';
import ConnectionStatusStore from './ConnectionStatusStore';
import ReconnectingWebSocket from 'reconnecting-websocket';
import wsUrl from '../lib/ws';

let velocityStore = new VelocityStore();
let fusionStore = new FusionStore();
let orientationStore = new OrientationStore();
let aboutInfoStore = new AboutInfoStore();
let warningMessageStore = new WarningMessageStore();
let connectionStatusStore = new ConnectionStatusStore();
let trailStore = new TrailStore();

//orientationStore fetching is started when needed, don't need to start it here
aboutInfoStore.startFetching();
warningMessageStore.startFetching();

// periodically update the fps display
setInterval(() => {
  velocityStore.updateFps();
}, 1000);

function updateStores() {
  const url = wsUrl();
  const socket = new ReconnectingWebSocket(url);

  socket.onopen = () => {
    console.log('store ws open');
    connectionStatusStore.setConnected(true);
    warningMessageStore.setConnectionOpen();
  };

  socket.onclose = () => {
    console.log('store ws close');
    connectionStatusStore.setConnected(false);
    warningMessageStore.setConnectionClosed();
  };

  socket.onmessage = (e) => {
    const json = (function (raw) {
      try {
        return JSON.parse(raw);
      } catch (err) {
        return false;
      }
    })(e.data);

    if (!json) {
      return;
    }

    if (json.channel === 'velocity') {
      velocityStore.setFromWs(json.payload);
    }
    if (json.channel === 'roll_pitch_yaw') {
      fusionStore.setRollPitchYawFromWs(json.payload);
    }
    if (json.channel === 'position_local') {
      fusionStore.setPositionLocalFromWs(json.payload);
      trailStore.addPostition(json.payload);
    }
    if (json.channel === 'position_local_std') {
      fusionStore.setPositionLocalStdFromWs(json.payload);
    }
    if (json.channel === 'fusion_velocity') {
      fusionStore.setVelocityFromWs(json.payload);
    }
    if (json.channel === 'fusion_velocity_std') {
      fusionStore.setVelocityStdFromWs(json.payload);
    }
    if (json.channel === 'fusion_reset') {
      trailStore.resetTrail();
    }
  };
}

updateStores();

const storesContext = React.createContext({
  velocityStore: velocityStore,
  fusionStore: fusionStore,
  orientationStore: orientationStore,
  aboutInfoStore: aboutInfoStore,
  warningMessageStore: warningMessageStore,
  connectionStatusStore: connectionStatusStore,
  trailStore: trailStore,
});

export const useStores = () => React.useContext(storesContext);
