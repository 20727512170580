import React, { useState } from 'react';
import { Popup, Button, Message } from 'semantic-ui-react';

function SendReboot() {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return fetch('/api/v1/about/reboot', options);
}

const RebootButton = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const reset = () => {
    setIsSubmitting(false);
    setIsSubmitSuccess(false);
    setSubmitError('');
  };

  const onClick = async () => {
    if (isSubmitting) {
      return;
    }
    reset();
    const result = await SendReboot();
    if (!result.ok) {
      const body = await result.json();
      console.log('submit error', body);
      setSubmitError("Error " + body.error);
      setIsSubmitSuccess(false);
    } else {
      console.log('submit success');
      setIsSubmitSuccess(true);
      setSubmitError('');
      //setTimeout(() => {
      //  setIsSubmitSuccess(false);
      //}, 5000);
    }
    setIsSubmitting(false);
  };

  return (
    <Popup
      trigger={
        <Button type="button" name="cog" color="grey">
          Reboot
        </Button>
      }
      content={
        <div>
          <Button
            color={submitError ? 'red' : isSubmitSuccess ? 'green' : 'grey'}
            onClick={onClick}
            name={'redo'}
            content="Confirm reboot"
          />
          <div className="top-padding">
            {submitError && <Message color="yellow" header="Failed to store" content={submitError} />}
            {isSubmitSuccess && <Message color="olive" header="Rebooting" content="System is now rebooting. Depending on your network setup it might get a new IP address."/>}
          </div>
        </div>
      }
      on="click"
      position="top left"
      onOpen={reset}
    />
  );
};

export { RebootButton };
