import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Segment, List, Button } from 'semantic-ui-react'
import ReleaseNotes from '../Components/ReleaseNotes'
import { useStores } from '../Stores/use-stores'
import { VersionDetail, ErrorMessage, LoadingMessage } from '../Components/VersionInfo'


const Description = () => (
    <Segment>
        <h4>Operational Principle</h4>
        <p>
        The DVL estimates velocity relative to the sea bottom by sending acoustic waves from the four angled transducers and then measure the frequency shift (doppler effect) from the received echo.
        By combining the measurements of all four transducers and the time between each acoustic pulse, it is possible to very accurately estimate the speed and direction of movement.
        </p>

        <a target="new" href="https://waterlinked.com/dvl"><Button>More information</Button></a>
    </Segment>
)

const VersionView = observer(() => {
    const { aboutInfoStore } = useStores()
    const { version, versionShort, chipid, loadingError, isLoading } =aboutInfoStore

    const proto = window.location.protocol // http or https
    const host = window.location.host.split(":")[0] // Remove any port (if present)
    let updateURL = `${proto}//${host}:9000`
    if (isLoading) {
        return (<LoadingMessage/>)
    } else if (loadingError) {
        return (<ErrorMessage>{loadingError}</ErrorMessage>)
    }
    return (
        <VersionDetail
            version={version}
            versionShort={versionShort}
            chipid={chipid}
            updateURL={updateURL}
        />
    )
})

/*
const HardwareSection = () => {
    return (
        <div>
            <h2>Hardware</h2>
            <Segment>
            <LEDButton/> Click to flash LED on DVL.
            <p/>
            <FactoryResetButton/> Click to reset all settings to factory defaults.
            </Segment>
        </div>
    )
}
*/

class About extends Component {
    render() {
        return (
            <Segment>
                <Segment>
                <h3>Version information</h3>
                <VersionView/>
                <h3>Browser compatibility</h3>
                <p>This GUI has been tested and verified to work with the following browsers:</p>
                <List>
                    <List.Item icon="chrome" content="Chrome 119 (Recommended)" />
                    <List.Item icon="firefox" content="Firefox 120" />
                </List>
                </Segment>

                <Description/>

                <h3>Release notes</h3>
                <ReleaseNotes/>

                <p>&nbsp;</p>
            </Segment>
        )
    }
}

export default About;
