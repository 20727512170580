import React, { useState } from 'react';
import { Dropdown, Form, Header, Icon, Message, Segment, Table } from 'semantic-ui-react';
import useSWR, { mutate } from 'swr';
import { HeaderWithHelp } from '../Components/HelpText';
import { fetcher } from '../lib/fetch';
import useForm from '../lib/useForm';

const ShowRow = ({ title, children, extra }) => (
    <Table.Row>
        <Table.Cell>{title}</Table.Cell>
        <Table.Cell>{children}</Table.Cell>
        <Table.Cell>{extra}</Table.Cell>
    </Table.Row>
);

const ShowValueIfTruthy = ({ title, value, className = '' }) => {
    if (!value) {
        return null;
    }
    return (
        <ShowRow title={title}>
            <strong className={className}>{value}</strong>
        </ShowRow>
    );
};

const ShowRowEdit = ({ title, center, children }) => (
    <Table.Row>
        <Table.Cell>{title}</Table.Cell>
        <Table.Cell>{center}</Table.Cell>
        <Table.Cell>{children}</Table.Cell>
    </Table.Row>
);

function isNumberWithin(val, min, max) {
    if (isNaN(val)) {
        return false;
    }
    if (val < min) {
        return false;
    }
    if (val > max) {
        return false;
    }
    return true;
}

async function ApplyPortSettings(id, port) {
    const body = {
        id: id,
        name: '',
        type: '',
        port: port.toString(),
        clients: 0,
        format: '',
        error: '',
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    };
    console.log('ApplyPortSettings', body);
    return fetch('/api/v1/outputs/' + id, options);
}

function validatePorts(values) {
    let errors = {};
    if (!isNumberWithin(values.port, 1024, 9999)) {
        errors.port = 'Must be between 1024 and 9999';
    }
    console.log('Validation of ports', values, errors);
    return errors;
}

const EditPort = ({ id, port, stopEdit, children }) => {
    const onSubmit = async (values) => {
        console.log('Submit', values);
        setSubmitError(false);
        setIsSubmitSuccess(false);
        setIsSubmitting(true);
        const result = await ApplyPortSettings(id, parseInt(values.port));
        if (!result.ok) {
            const body = await result.json();
            console.log('submit error', body);
            setSubmitError(body.error);
            setIsSubmitSuccess(false);
        } else {
            console.log('submit success');
            setIsSubmitSuccess(true);
            setSubmitError('');
            mutate('/api/v1/outputs');
            setTimeout(() => {
                stopEdit();
            }, 10);
        }
        setIsSubmitting(false);
    };

    const initial = { port };
    const { values, errors, handleChange, handleSubmit } = useForm(onSubmit, validatePorts, initial);

    const [submitError, setSubmitError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

    const onChange = (e) => {
        handleChange(e.target.name, e.target.value);
    };

    return (
        <ShowRowEdit title="Port" center={children}>
            <Form size="small" onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Input name="port" error={errors.port} value={values.port} type="number" onChange={onChange} />

                    <Form.Button icon positive type="submit" disabled={isSubmitting}>
                        Apply&nbsp;
                        {isSubmitting && <Icon name="circle notched" loading />}
                        {isSubmitSuccess && <Icon name="checkmark" />}
                    </Form.Button>

                    <Form.Button icon color="grey" type="button" disabled={isSubmitting} onClick={stopEdit}>
                        Cancel&nbsp;
                        {isSubmitting && <Icon name="circle notched" loading />}
                    </Form.Button>
                </Form.Group>

                {submitError && <Message icon="exclamation" content={submitError} />}
            </Form>
        </ShowRowEdit>
    );
};

// TODO: Refactor to reuse between port/format
async function ApplyFormatSettings(id, format) {
    const body = {
        id: id,
        name: '',
        type: '',
        port: '',
        clients: 0,
        format: format,
        error: '',
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    };
    console.log('ApplyFormatSettings', body);
    return fetch('/api/v1/outputs/' + id, options);
}

function validateFormat(values) {
    let errors = {};
    if (!isNumberWithin(values.format, 0, 6)) {
        errors.port = 'Must be between 0 and 6';
    }
    console.log('Form validation', values, errors);
    return errors;
}

const formatDisabled = '0';

const EditFormat = ({ id, format, stopEdit, children }) => {
    const onSubmit = async (values) => {
        console.log('Submit', values);
        setSubmitError(false);
        setIsSubmitSuccess(false);
        setIsSubmitting(true);
        const result = await ApplyFormatSettings(id, values.format);
        if (!result.ok) {
            const body = await result.json();
            console.log('submit error', body);
            setSubmitError(body.error);
            setIsSubmitSuccess(false);
        } else {
            console.log('submit success');
            setIsSubmitSuccess(true);
            setSubmitError('');
            mutate('/api/v1/outputs');
            setTimeout(() => {
                stopEdit();
            }, 10);
        }
        setIsSubmitting(false);
    };

    const initial = { format };
    const { values, errors, handleChange, handleSubmit } = useForm(onSubmit, validateFormat, initial);

    const [submitError, setSubmitError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

    const onChange = (e, data) => {
        
        handleChange('format', data.value);
    };

    const formatOptions = [
        {
            key: '0',
            text: 'Disabled',
            value: formatDisabled,
        },
        {
            key: '1',
            text: 'Backward compatible',
            value: '1',
        },
        {
            key: '2',
            text: 'PD6',
            value: '2',
        },
        {
            key: '3',
            text: 'Latest',
            value: '3',
        },
        {
            key: '6',
            text: 'PD4',
            value: '6',
        }
        /*
        {
            key: '4',
            text: 'Latest Velocity only',
            value: '4',
        },
        {
            key: '5',
            text: 'Latest Position only',
            value: '5',
        },
*/
    ];
    return (
        <ShowRowEdit title="Protocol Format" center={children}>
            <Form size="small" onSubmit={handleSubmit}>
                <Form.Group>
                    <Dropdown
                        button
                        options={formatOptions}
                        value={values.format}
                        onChange={onChange}
                        error={errors.format}
                    />
                    <Form.Button icon positive type="submit" disabled={isSubmitting}>
                        Apply&nbsp;
                        {isSubmitting && <Icon name="circle notched" loading />}
                        {isSubmitSuccess && <Icon name="checkmark" />}
                    </Form.Button>

                    <Form.Button icon color="grey" type="button" disabled={isSubmitting} onClick={stopEdit}>
                        Cancel&nbsp;
                        {isSubmitting && <Icon name="circle notched" loading />}
                    </Form.Button>
                </Form.Group>
                {submitError && <Message icon="exclamation" content={submitError} />}
            </Form>
        </ShowRowEdit>
    );
};

const ShowValueOrEdit = ({ id, title, value, editable }) => {
    const [isEdit, setIsEdit] = useState(false);
    const startEdit = () => {
        setIsEdit(true);
    };
    const stopEdit = () => {
        setIsEdit(false);
    };
    return (
        <>
            {isEdit ? (
                <EditPort id={id} port={value} stopEdit={stopEdit}>
                    <strong>{value}</strong>
                </EditPort>
            ) : (
                <ShowRow title={title} extra={editable && <Icon name="pencil" onClick={startEdit} />}>
                    <strong>{value}</strong>
                </ShowRow>
            )}
        </>
    );
};

const ShowValueOrEditFormat = ({ id, title, initialValue, value, editable }) => {
    const [isEdit, setIsEdit] = useState(false);
    const startEdit = () => {
        setIsEdit(true);
    };
    const stopEdit = () => {
        setIsEdit(false);
    };
    return (
        <>
            {isEdit ? (
                <EditFormat id={id} format={initialValue} stopEdit={stopEdit}>
                    <strong>{value}</strong>
                </EditFormat>
            ) : (
                <ShowRow title={title} extra={editable && <Icon name="pencil" onClick={startEdit} />}>
                    <strong>{value}</strong>
                </ShowRow>
            )}
        </>
    );
};
const ShowProtocol = ({ id, title, port, type, format, clients, error, children }) => {
    const portIsEditable = id === 'tcp_pd6' || id === "tcp_pd4";
    const isSerial = id === 'serial';
    const formatValue = isSerial ? format.split(',')[0] : format;
    const formatId = isSerial ? format.split(',')[1] || formatDisabled : formatDisabled;
    return (
        <Segment attached>
            <Header as="h4">{title}</Header>
            <Table basic="very" collapsing>
                <Table.Body>
                    {port && <ShowValueOrEdit id={id} title="Port" value={port} editable={portIsEditable} />}
                    <ShowValueIfTruthy title="Type" value={type} />
                    {/*
                    <ShowValueIfTruthy title="Protocol format" value={format} className="capitalize" />
                    */}
                    {format && (
                        <ShowValueOrEditFormat
                            id={id}
                            title="Protocol Format"
                            value={formatValue}
                            initialValue={formatId}
                            editable={isSerial}
                        />
                    )}
                    {clients >= 0 && (
                        <ShowRow title="Connected clients">
                            <strong>{clients}</strong>&nbsp;
                            {clients > 0 && <Icon name="check circle" color="green" />}
                        </ShowRow>
                    )}
                    <ShowValueIfTruthy title="Error" value={error} />
                    {children}
                </Table.Body>
            </Table>
        </Segment>
    );
};

const OutputInner = () => {
    const { data, error } = useSWR('/api/v1/outputs/', fetcher, { refreshInterval: 1000, dedupingInterval: 100 });
    if (error) {
        return <p>Error getting outputs</p>;
    }
    if (!data) {
        return <p>Loading..</p>;
    }
    return (
        <>
            {data.map((el) => (
                <ShowProtocol
                    key={el.id}
                    id={el.id}
                    title={el.name}
                    type={el.type}
                    port={el.port}
                    format={el.format}
                    clients={el.clients}
                    error={el.error}
                />
            ))}
        </>
    );
};

const OutputRoute = () => (
    <Segment>
        <HeaderWithHelp
            as="h3"
            heading="Outputs"
            help="Configure ports and format for the available API protocols. Disable serial protocol if not used to minimize latency for other protocols."
        />
        <OutputInner />
    </Segment>
);

export default OutputRoute;
