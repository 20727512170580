import { observable, action, decorate, computed } from 'mobx'
import axios from 'axios'
import { GetArrayIndexOrFallback, IsDefinedFinite, NoValue } from '../lib/format'

const times = [];

class Velocity {
    // Observable values
    vx = 0
    vy = 0
    vz = 0
    fom = 0
    altitude = 0
    velocity_valid = false
    range_mode = -1
    beam_nsd = [0, 0, 0, 0]
    beam_rssi = [0, 0, 0, 0]
    beam_velocity = [0, 0, 0, 0]
    beam_distance = [0, 0, 0, 0]
    beam_valid = [0, 0, 0, 0]
    fps = 0

    // Fetching state
    loadSuccess = false
    isLoading = true
    loadingError = ""
    isRunning = false

    // Computed values
    get speed() {
        // Total speed
        var speed = Math.sqrt(this.vx*this.vx + this.vy*this.vy + this.vz*this.vz)
        if (isNaN(speed)) {
          speed = 0
        }
        return speed
    }


    get horizontalSpeed() {
        // Horizontal speed
        var speed = Math.sqrt(this.vx*this.vx + this.vy*this.vy + this.vz*this.vz)
        if (isNaN(speed)) {
            speed = 0
        }
        return speed
    }

    get verticalSpeed() {
        // Convenience function for vertical speed
        return this.vz
    }

    get horizontalStationary() {
        return this.horizontalSpeed < 0.1
    }

    get direction() {
        // Horizontal direction
        if (this.horizontalStationary) {
            return 0.0
        }

        var direction = 180.0 * Math.atan2(this.vy, this.vx) / Math.PI
        if (direction < 0) {
          direction += 360
        }
        if (isNaN(direction)) {
          direction = 0
        }
        return direction
    }

    get transducers() {
        // The HTTP API is not transducer oriented, so we need to remap
        // to work better with React
        return [0, 1, 2, 3].map(d => {
            return {
                id: d,
                velocity: GetArrayIndexOrFallback(this.beam_velocity, d, NoValue),
                distance: GetArrayIndexOrFallback(this.beam_distance, d, NoValue),
                rssi: GetArrayIndexOrFallback(this.beam_rssi, d, NoValue),
                nsd: GetArrayIndexOrFallback(this.beam_nsd, d, NoValue),
                snr: GetArrayIndexOrFallback(this.beam_rssi - this.beam_nsd +42, d, NoValue),
                lock: GetArrayIndexOrFallback(this.beam_valid, d, 0) > 0,
            }
        })
    }

    // updateFPS removes the old entries from the log and updates "fps" to
    // the frames per second for averaged over "avgTime"
    updateFps() {
        const avgTime = 3; // seconds to average over
        const now = performance.now();
        // Remove entries older than "avgTime" from the list
        while (times.length > 0 && times[0] <= now - 1000*avgTime) {
            times.shift();
        }
        // update frames pr second
        if (times.length === 0) {
            this.fps = 0;
            return;
        }
        this.fps = times.length / avgTime;
    }

    // addFpsEntry adds a new velocity measurement to the list of measurements
    addFpsEntry() {
        const now = performance.now();
        times.push(now);
    }

    // Actions
    setFromWs(data) {
        this.addFpsEntry();
        if (IsDefinedFinite(data, "vx")) {this.vx = data.vx}
        if (IsDefinedFinite(data, "vy")) {this.vy = data.vy}
        if (IsDefinedFinite(data, "vz")) {this.vz = data.vz}
        if (IsDefinedFinite(data, "std")) {this.fom = data.std  /* TODO: Rename "std" to "fom" in backend */}
        if (IsDefinedFinite(data, "altitude")) {this.altitude = data.altitude}
        if (IsDefinedFinite(data, "range_mode")) {this.range_mode = data.range_mode}
        this.velocity_valid = (data.velocity_valid ?  true : false)

        const t = data.transducers
        for (let i=0; i<4; i++) {
            this.beam_distance[i] = t[i].distance || 0
            this.beam_velocity[i] = t[i].velocity || 0
            this.beam_nsd[i] = t[i].nsd ||0
            this.beam_rssi[i] = t[i].rssi || 0
            this.beam_valid[i] = t[i].is_valid ? 1 : 0
        }
    }

    fetch() {
        if (!this.isRunning) { return false }
        this.isLoading = true
        axios.get('/api/v1/velocity')
        .then(response => {
            this.addFpsEntry();
            this.loadSuccess = true
            this.isLoading = false
            this.loadingError = ""
            if (IsDefinedFinite(response.data, "vx")) {this.vx = response.data.vx}
            if (IsDefinedFinite(response.data, "vy")) {this.vy = response.data.vy}
            if (IsDefinedFinite(response.data, "vz")) {this.vz = response.data.vz}
            if (IsDefinedFinite(response.data, "fom")) {this.fom = response.data.fom}
            if (IsDefinedFinite(response.data, "altitude")) {this.altitude = response.data.altitude}
            if (IsDefinedFinite(response.data, "range_mode")) {this.range_mode = response.data.range_mode}
            (response.data.velocity_valid) ? this.velocity_valid = response.data.velocity_valid : this.velocity_valid = false
            this.beam_distance = response.data.beam_distance
            this.beam_velocity = response.data.beam_velocity
            this.beam_nsd = response.data.beam_nsd
            this.beam_rssi = response.data.beam_rssi
            this.beam_valid = response.data.beam_valid
            setTimeout(() => this.fetch(), 200)  // 200ms seems like a good trade-off between CPU usage and update speed
        })
        .catch((e) => {
            this.loadSuccess = false
            this.isLoading = false
            this.loadingError = e.toString()

            this.vx = 0
            this.vy = 0
            this.vz = 0
            this.fom = 1000
            this.altitude = -1
            this.velocity_valid = false
            this.range_mode = -1
            this.beam_distance = [0,0,0,0]
            this.beam_velocity = [0,0,0,0]
            this.beam_nsd = [0,0,0,0]
            this.beam_rssi = [0,0,0,0]
            this.beam_valid = [0,0,0,0]

            console.log("error", e)
            setTimeout(() => this.fetch(), 2000)
        })
    }

    startFetching() {
        if (this.isRunning) {
            console.log("already running")
            return false
        }
        this.isRunning = true
        this.fetch()
    }
    stopFetching() {
        console.log("stopping")
        this.isRunning = false
    }
}

decorate(Velocity, {
    vx: observable,
    vy: observable,
    vz: observable,
    altitude: observable,
    velocity_valid: observable,
    beam_distance: observable,
    beam_velocity: observable,
    beam_nsd: observable,
    beam_rssi: observable,
    beam_valid: observable,
    fps: observable,
    speed: computed,
    horizontalSpeed: computed,
    verticalSpeed: computed,
    horizontalStationary: computed,
    direction: computed,
    transducers: computed,
    fetch: action,
    setFromWs: action,
    updateFps: action,
    addFpsEntry: action,
})

export default Velocity
