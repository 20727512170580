import { computed, observable, action, decorate } from 'mobx'
import axios from 'axios'
import toastMaster from '../Toast';

class MessageModel {
    // Observable values
    id = 0
    message = ""
    severity = ""
    visible =  true

    constructor(id, message, severity) {
        this.id = id
        this.message = message
        this.severity = severity
        this.visible = true
    }

    // Computed values
    get numericSeverity() {
        if (this.severity === "error") { return 2 }
        if (this.severity === "warning") { return 1 }
        return 0
    }

    // Actions
    dismiss() {
        console.log("dismissing", this.id)
        this.visible = false
    }
}

decorate(MessageModel, {
    id: observable,
    message: observable,
    severity:observable,
    visible: observable,
    numericSeverity: computed,
    dismiss: action,
})

const temperature_high_id = 'warnings:temperature_high'
const connection_error_id = 'conn-err'
const connection_error_severity = 'error'
const connection_error_msg_lost = 'No connection with DVL. Reconnecting...';
const connection_error_msg_shutdown = 'No connection with DVL. The DVL is likely in thermal shutdown and will reboot once temperature lowers to 50 &deg;C.';


function severityCompare(a, b) {
    if (a.numericSeverity > b.numericSeverity) {
        return -1
    }
    if (a.numericSeverity < b.numericSeverity) {
        return 1
    }
    return 0
}

class MessageStore {
    // Fetching state
    hasLoaded = false
    isLoading = false
    isStoring = false
    isRunning = false
    messageRegistry = observable.map()
    toastsActive = new Map()

    // Computed values
    get count(){
        return this.messageRegistry.size
    }
    get all(){
        return this.messageRegistry.values()
    }
    get allIds(){
        return Array.from(this.messageRegistry.keys())
    }
    get allSorted(){
        // Sort messages by priority
        let arr = Array.from(this.messageRegistry.values())
        return arr.sort(severityCompare)
    }

    // Actions
    clear(){
        this.messageRegistry.clear()
    }
    get(id){
        return this.messageRegistry.get(id)
    }
    addMessage(id, message, severity){
        this.messageRegistry.set(id, new MessageModel(id, message, severity))
    }
    setConnectionClosed() {
        if (this.messageRegistry.has(temperature_high_id)) {
            // Temperature high is asserted, show message indicating likely thermal shutdown
            this.addMessageIfNotExists(connection_error_id, connection_error_msg_shutdown, connection_error_severity)
        } else {
            // Normal disconnection message
            this.addMessageIfNotExists(connection_error_id, connection_error_msg_lost, connection_error_severity)
        }
    }
    setConnectionOpen() {
        this.removeIds([connection_error_id]);
    }
    addMessageIfNotExists(id, message, severity){
        if (!this.messageRegistry.has(id)) {
            this.addMessage(id, message, severity)
        }

        if (!this.toastsActive.has(id)) {
            // Toast is not already showing, add it
            let toast = toastMaster.open({
                type: severity,
                message: message
            })
            this.toastsActive.set(id, toast)
        }
    }
    removeIds(idList){
        idList.forEach(id => {
            this.messageRegistry.delete(id)
            if (this.toastsActive.has(id)) {
                // Remove toast, it is no longer relevant.
                const toast = this.toastsActive.get(id)
                toastMaster.dismiss(toast)
                this.toastsActive.delete(id)
            }
        })
    }
    fetch(){
        this.isLoading = true
        axios.get('/api/v1/warnings/', { timeout: 3000})
        .then(response => {
            this.hasLoaded = true
            this.isLoading = false
            this.loadingError = ""

            const currentIds = response.data.map(d => d.id)

            // Find ids in registry to that are no longer active
            const remove = this.allIds.filter(id => currentIds.indexOf(id) === -1)
            this.removeIds(remove)

            // Add new messages
            response.data.forEach(d => { this.addMessageIfNotExists(d.id, d.message, d.severity) })
            setTimeout(() => this.fetch(), 3000)

        })
        .catch(e => {
            console.log("err", e)
            this.isLoading = false
            this.loadingError = e.toString()
            this.setConnectionClosed()
            setTimeout(() => this.fetch(), 3000)
        })
    }
    startFetching() {
        if (this.isRunning) {
            console.log("already running")
            return false
        }
        this.isRunning = true
        this.fetch()
    }
    stopFetching() {
        console.log("stopping")
        this.isRunning = false
    }
}

decorate(MessageStore, {
    hasLoaded: observable,
    isLoading: observable,
    isStoring: observable,
    isRunning: observable,
    messageRegistry: observable,
    count: computed,
    all: computed,
    allIds: computed,
    allSorted: computed,
    clear: action,
    get: action,
    addMessage: action,
    addMessageIfNotExists: action,
    removeIds: action,
    fetch: action,
    startFetching: action,
    stopFetching: action,
    setConnectionClosed: action,
    setConnectionOpen: action,
})

export default MessageStore
