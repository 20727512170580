import React, { useState, useEffect } from 'react';
import { Segment, Icon, Header, Form, Button, Table, Message } from 'semantic-ui-react';
import { fetcher } from '../lib/fetch';
import useSWR, { mutate } from 'swr';
import fetch from 'unfetch';
import { Configurator } from '../Components/FormStore';

function SetNTP(ntp_server) {
  const data = {
    ntp_server: String(ntp_server),
  };
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return fetch('/api/v1/time/ntp', options);
}

function SetManual(time) {
  const data = {
    time: String(time),
  };
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return fetch('/api/v1/time/manual', options);
}

const refreshNTP = () => {
  mutate('/api/v1/time');
};

function CurrentNTPServer() {
  const { data, error } = useSWR('/api/v1/time', fetcher);
  if (error) {
    console.log('config load error', error);
    return (
      <Message
        icon="exclamation"
        header="Cannot load time information"
        content={'Check network connection (' + error.toString() + ')'}
      />
    );
  }
  if (!data) {
    return <Icon name="spinner" loading />;
  }

  const date = new Date(data.time).toString() || '?';
  return (
    <>
      <Header as="h3">System time status</Header>
      <Table basic="very" collapsing>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Time source</Table.Cell>
            <Table.Cell textAlign="right">
              <strong>{data.ntp_enabled ? 'Network Time Protocol' : 'Manual'}</strong>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Time synchronized from NTP</Table.Cell>
            <Table.Cell textAlign="right">
              <strong>{data.ntp_synchronized ? 'Yes' : 'No'}</strong>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>NTP server</Table.Cell>
            <Table.Cell textAlign="right">
              <strong>{data.ntp_server}</strong>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>System time</Table.Cell>
            <Table.Cell textAlign="right">
              <strong>{date}</strong>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Button onClick={refreshNTP}>
        <Icon name="refresh" />
        Refresh{' '}
      </Button>
    </>
  );
}

function NTPSet({ ntp_server }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [ntpServer, setNtpServer] = useState(ntp_server);

  const onSubmit = async () => {
    setIsSubmitting(true);
    setIsSubmitSuccess(false);
    setSubmitError('');
    const result = await SetNTP(ntpServer);
    if (!result.ok) {
      const body = await result.json();
      console.log('submit error', body);
      setSubmitError(body.error);
      setIsSubmitSuccess(false);
    } else {
      console.log('submit success');
      setIsSubmitSuccess(true);
      setSubmitError('');
      setTimeout(() => {
        setIsSubmitSuccess(false);
      }, 5000);
    }
    setIsSubmitting(false);
    refreshNTP();
  };
  const onChangeNtpServer = (e) => {
    setNtpServer(e.target.value);
  };

  return (
    <>
      <Form.Group>
        <Form.Input
          value={ntpServer}
          name="ntpServer"
          onChange={onChangeNtpServer}
          label="NTP server (blank for default)"
        />
      </Form.Group>

      <div className="spacing">
        <Button
          icon
          positive={submitError === ''}
          negative={submitError !== ''}
          disabled={isSubmitting}
          onClick={onSubmit}
        >
          Apply
          {isSubmitting && <Icon name="circle notched" loading />}
          {isSubmitSuccess && <Icon name="checkmark" />}
        </Button>
      </div>
      <div className="top-padding">
        {submitError && <Message color="yellow" header="Failed to store" content={submitError} />}
      </div>
    </>
  );
}

function ManualSet() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    let secTimer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(secTimer);
  }, []);

  const onSubmit = async () => {
    setIsSubmitting(true);
    setIsSubmitSuccess(false);
    setSubmitError('');
    const now = new Date().toISOString();
    const result = await SetManual(now);
    if (!result.ok) {
      const data = await result.json();
      console.log('submit error', data);
      setSubmitError(data.error || 'unknown error');
      setIsSubmitSuccess(false);
    } else {
      console.log('submit success');
      setIsSubmitSuccess(true);
      setSubmitError('');
      setTimeout(() => {
        setIsSubmitSuccess(false);
      }, 5000);
    }
    setIsSubmitting(false);
    refreshNTP();
  };
  console.log('err', submitError);
  return (
    <>
      <div className="top-padding">Your computer time</div>
      <div className="top-padding">
        <strong>{currentTime.toString()}</strong>
      </div>
      <div className="top-padding">
        <Button
          icon
          positive={submitError === ''}
          negative={submitError !== ''}
          disabled={isSubmitting}
          onClick={onSubmit}
        >
          Set manual time
          {isSubmitting && <Icon name="circle notched" loading />}
          {isSubmitSuccess && <Icon name="checkmark" />}
        </Button>
      </div>

      <div className="top-padding">
        {submitError && <Message color="yellow" header="Failed to store" content={submitError} />}
      </div>
    </>
  );
}

function remap(value, desc) {
  return { value: value, description: desc };
}

function NTPConfig({ ntp_server, ntp_enabled }) {
  const [ntpEnabled, setNTPEnabled] = useState(ntp_enabled);

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const onChangeCheckBox = (e) => {
    setNTPEnabled(!ntpEnabled);
  };
  return (
    <>
      <Segment attached>
        <Form onSubmit={handleSubmit}>
          <Configurator
            heading="System time source"
            name="ntp"
            help="NTP is the best option if a connection to the internet is available, if not time can be set manually"
            value={String(ntpEnabled)}
            error={''}
            options={[remap('true', 'Network Time Protocol (NTP)'), remap('false', 'Manual time')]}
            onChange={onChangeCheckBox}
          />

          <div className="top-padding">{ntpEnabled ? <NTPSet ntp_server={ntp_server} /> : <ManualSet />}</div>
        </Form>
      </Segment>

      <Segment attached>
        <CurrentNTPServer />
      </Segment>
    </>
  );
}

const FetchNTP = () => {
  const { data, error } = useSWR('/api/v1/time', fetcher);
  if (error) {
    console.log('config load error', error);
    return <Message icon="exclamation" header="Cannot load time information" content={'Check network connection'} />;
  }
  if (!data) {
    return <Icon name="spinner" loading />;
  }

  return <NTPConfig {...data} />;
};

export default FetchNTP;
