import fetch from 'unfetch'

const fetcher = (url) => {
  var headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
  return fetch(url, {headers: headers})
  .then(r => r.json())
}

function superFetch(endpoint, authenticated) {
    var headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
    let token = localStorage.getItem('access_token') || null
    if (authenticated) {
      if (token) {
        headers["Authorization"] = `Bearer ${token}`
      } else {
        throw Error("No token saved!")
      }
    }
    return fetch(endpoint, {
      headers: headers,
      method: "GET",
      //body: JSON.stringify(payload)
    })
    .then(response => {
      if (!response.ok) {
        throw Error("Response: " + response.statusText)
      }
      return response
    })
    .then(response => { return response.json() })
}


function superFetchPost(endpoint, authenticated, method, payload) {
  var headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
  let token = localStorage.getItem('access_token') || null
  if (authenticated) {
    if (token) {
      headers["Authorization"] = `Bearer ${token}`
    } else {
      throw Error("No token saved!")
    }
  }
  return fetch(endpoint, {
    headers: headers,
    method: method,
    body: JSON.stringify(payload)
  })
  .then(response => {
    if (!response.ok) {
      throw Error("Response: " + response.statusText)
    }
    return response
  })
}

function superFetchHeaders(endpoint, method, data) {
  var headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  return fetch(endpoint, {
      method: method,
      headers: headers,
      body: JSON.stringify(data)
  })
}


export { superFetch, superFetchPost, fetcher, superFetchHeaders };
