import React from 'react';
import { PropTypes } from 'prop-types';
import { fetcher } from '../lib/fetch'
import useSWR from 'swr'

class DopplerDirectionInner extends React.Component {
    /*

    Device coordinates

    ^ x
    |
    |  angle positive from x towards y
    |
    ------> y


    Screen coordinates

    ------> x
    |
    |  angle positve from x towards y
    |
    v y

    From device to screen: subtract 90 degrees to angle

    */
      render() {
        const width = 220
        const height = 240
        const radius = 50
        const maxSpeed = 1.0  // The speed that is needed to show full direction arrow (speeds above this is clamped)
        const stationarySpeed = 0.1 // Speeds below this is shown as a dot. This is to avoid arrow looking erratic.
        let {direction, speed, velocity_valid} = this.props
        let screenDirection = direction - 90
        if (screenDirection < 0) {
          screenDirection += 360
        }
        const rad = screenDirection / 180.0 * Math.PI
        let clampSpeed = Math.min(speed, maxSpeed)
        const circumferenceStroke = 10
        const arrowHeadSize = 8  // This should probably be based on the marker drawing below, now it is just set to a value that looks good
        let x = ((radius-circumferenceStroke/2-arrowHeadSize)*clampSpeed/maxSpeed)*Math.cos(rad)
        let y = ((radius-circumferenceStroke/2-arrowHeadSize)*clampSpeed/maxSpeed)*Math.sin(rad)
        const velocityValidColor = velocity_valid ? '#4292c6' : "#fa1f1f"
        return (
          <div title="Horizontal speed. Arrow show direction of travel. Length of arrow illustrates speed. Up on the illustration is forward on the ROV. The green circle represents the LED and is forward on the DVL.">
          <svg width={width} height={height} viewBox="-70 -70 140 140">
          <defs>
            <marker id='head' orient='auto' markerWidth='2' markerHeight='4'
                refX='0.1' refY='2'>
              <path d='M0,0 V4 L2,2 Z' fill={velocityValidColor} fillOpacity="0.8"/>
            </marker>
          </defs>
            {/* Circumference: */}
            <circle cx={0} cy={0} r={radius} stroke="lightgray" strokeOpacity="0.5" strokeWidth={circumferenceStroke} fill="none" />
            {/* Speed arrow or dot if slow movement: */}
            {speed > stationarySpeed ? (
              <line x1={0} y1={0} x2={x} y2={y}
                markerEnd='url(#head)'
                strokeWidth='4' fill='none' stroke={velocityValidColor} strokeOpacity="0.8"
                />
            ) : (
              <circle cx={x} cy={y} r={5} stroke="none" fill={velocityValidColor} fillOpacity="0.8"/>
            )}
            <g transform={"rotate(" + this.props.mounting_rotation_offset + ")"}>
            {/* Dot indicating Forward/LED position: */}
            <circle cx={0} cy={0 - radius} r={3} stroke="none" fill="#41ab5d" fillOpacity="0.4" />
            {/* Illustration of LEMO Connector */}
            <polygon points={`${0-5}, ${0 + radius + 5}
                              ${0-3}, ${70}
                              ${0+3}, ${70}
                              ${0+5}, ${0 + radius + 5}`
                            }
                    fill="black"
                    fillOpacity="0.6"
                    />
            </g>
          </svg>
          </div>
        )
      }
}

const DopplerDirection = (props) => {
  const { data, error } = useSWR('/api/v1/config', fetcher)
  if (error) {
    console.log("DopplerDirection has no mounting rotation offset")
  }
  const mounting_rotation_offset = (data) ? data.mounting_rotation_offset || 0 : 0
  return (
    <DopplerDirectionInner mounting_rotation_offset={mounting_rotation_offset} {...props} />
  )
}


DopplerDirection.propTypes = {
  direction: PropTypes.number.isRequired,
  speed: PropTypes.number.isRequired,
  velocity_valid: PropTypes.bool.isRequired,
}

export default DopplerDirection
