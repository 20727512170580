import React from 'react';
import { Segment, Grid, Header } from 'semantic-ui-react';
import '../Graph.css';
import { observer } from 'mobx-react';
import { useStores } from '../Stores/use-stores';
import PositionMap from '../Components/Map/PositionMap';
import DopplerDetails from '../Components/DopplerDetails';
import DopplerDirection from '../Components/DopplerDirection';
import DopplerVertical from '../Components/DopplerVertical';
import DeadReckoningStatus from '../Components/DeadReckoningStatus';

const ShowDopplerDetails = observer(() => {
  const { velocityStore } = useStores();
  const { altitude, speed, direction, fom, velocity_valid, fps } = velocityStore;
  return (
    <DopplerDetails
      altitude={altitude}
      speed={speed}
      direction={direction}
      fom={fom}
      velocity_valid={velocity_valid}
      fps={fps}
    />
  );
});

const ShowDopplerDirection = observer(() => {
  const { velocityStore } = useStores();
  const { horizontalSpeed, direction, velocity_valid } = velocityStore;
  return <DopplerDirection speed={horizontalSpeed} direction={direction} velocity_valid={velocity_valid} />;
});

const ShowDopplerVertical = observer(() => {
  const { velocityStore } = useStores();
  return <DopplerVertical speed={velocityStore.verticalSpeed} />;
});


const Dashboard = ({ isFullScreen, setIsFullscreen }) => {
  return (
    <Segment attached>
      <Grid divided>
        {!isFullScreen && (
          <>
            <Grid.Column textAlign="center" mobile={16} tablet={8} computer={8} largeScreen={4} widescreen={6}>
              <Header as="h5">Doppler velocity</Header>
              <ShowDopplerDetails />
            </Grid.Column>

            <Grid.Column textAlign="center" mobile={16} tablet={8} computer={8} largeScreen={6} widescreen={6}>
              <Header as="h5" title="Dead reckoning position details">
                Dead reckoning
              </Header>
              <DeadReckoningStatus />
            </Grid.Column>

            <Grid.Column textAlign="center" mobile={8} computer={8} largeScreen={3} widescreen={2}>
              <Header as="h5">Horizontal velocity</Header>
              <ShowDopplerDirection />
            </Grid.Column>

            <Grid.Column textAlign="center" mobile={8} computer={8} largeScreen={3} widescreen={2}>
              <Header as="h5">Vertical velocity</Header>
              <ShowDopplerVertical />
            </Grid.Column>
          </>
        )}
      </Grid>

      <Segment basic>
        <Grid centered columns={1}>
          <Grid.Row>
            <Grid.Column>
              <PositionMap isFullScreen={isFullScreen} setIsFullscreen={setIsFullscreen} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Segment>
  );
};

export default Dashboard;
