import React, { Component } from 'react';
import { extendObservable, action, toJS } from 'mobx'
import { Validator } from '../lib/validator';
import PropTypes from 'prop-types';
import { Input, Form, Segment, Message, Icon } from 'semantic-ui-react'
import ButtonSelector from '../Components/ButtonSelector'
import { HeaderWithHelp } from './HelpText'
import { RoundDecimalPlaces } from '../lib/format'

class GenericFormStore {
    constructor() {
        extendObservable(this, {
            getFlattenedValues: (valueKey = 'value') => {
                let data = {};
                let form = toJS(this.form).fields;
                Object.keys(form).forEach(key => {
                    data[key] = form[key][valueKey]
                });
                return data
            },
            getFlattenedAbsoluteValues: (convFunc, decimals=-1) => {
                let data = {}
                let form = toJS(this.form).fields;
                Object.keys(form).forEach(key => {
                    data[key] = parseFloat(form[key]["value"])
                    if (typeof convFunc === "function") {
                        data[key] = convFunc(data[key])
                    }
                    if (decimals > 0) {
                        data[key] = RoundDecimalPlaces(data[key], decimals)
                    }
                    if (form[key]["negated"]) {
                        data[key] = -data[key]
                    }
                })
                return data
            },
            getFlattenedAbsoluteMetricValues: (decimals=-1) => {
                return this.getFlattenedAbsoluteValues(this.toMetric, decimals)
            },
            onFieldChange: action((field, value) => {
                this.form.fields[field].value = value;
                var validation = new Validator(
                    this.getFlattenedValues('value'),
                    this.getFlattenedValues('rule'));
                const factor = this.form.meta.unitFactor || 1.0
                const decimals = this.form.meta.unitDecimals || 3
                this.form.meta.isValid = validation.passes(factor, decimals);
                this.form.fields[field].error = validation.errors.first(field)
            }),

            onAbsoluteMetricFieldChange: action((field, value, decimals=-1) => {
                this.form.fields[field].negated = value < 0
                let val = Math.abs(this.toUserPrefered(value))
                if (decimals >= 0) {
                    val = RoundDecimalPlaces(val, decimals)
                }
                this.onFieldChange(field, val)
            }),

            getAbsolute: (field) => {
                let value = parseFloat(this.form.fields[field].value)
                return this.form.fields[field].negated ? -value : value
            },

            getAbsoluteMetric: (field) => {
                return this.toMetric(this.getAbsolute(field))
            },

            toMetric: (value) => {
                const factor = this.form.meta.unitFactor || 1.0
                return value / factor
            },
            toUserPrefered: (value) => {
                const factor = this.form.meta.unitFactor || 1.0
                return value * factor
            },/*
            unitName: computed(() => {
                return this.form.meta.unitName || "meter"
            }),*/
            setError: action((errMsg) => {
                this.form.meta.error = errMsg
            }),
        })
    }
}

const SimpleFormInput = ({error, ...props}) => (
    <Form.Input
        className={"form-input " + (error ? "error" : "")}
         {...props}
         error={error ? true : false}
         type={props.type || 'text'}
         onChange={(e) => props.onChange(e.target.name, e.target.value)}
    />
)


SimpleFormInput.propTypes = {
    // Not possible to check for string or null
    //error: PropTypes.string,
    onChange: PropTypes.func.isRequired,
}

const FormInput = ({error, ...props}) => (
    <Form.Field className={"form-input " + (error ? "error" : "")}>
      <Input {...props}
            error={error ? true : false}
            type={props.type || 'text'}
            onChange={(e) => props.onChange(e.target.name, e.target.value)}/>
    </Form.Field>
)


FormInput.propTypes = {
    // Not possible to check for string or null
    //error: PropTypes.string,
    onChange: PropTypes.func.isRequired,
}

class Configurator extends Component {
    render() {
        const { heading, help, options, value, onChange } = this.props
        return (
            <div className="spacing">
                <HeaderWithHelp as="h5" heading={heading} help={help} />
                <ButtonSelector name={this.props.name} options={options} value={value} onChange={onChange}/>
                {this.props.children}
            </div>
        )
    }
}

Configurator.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        description: PropTypes.string,
    })).isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]).isRequired,
    onChange: PropTypes.func.isRequired,
    heading: PropTypes.string.isRequired,
    help: PropTypes.string.isRequired,
}



const FormSetupError = ({header, children}) => (
    <Segment>
      <Message>
      <Message.Header>{header}</Message.Header>
      <p>
      {children}
      </p>
      </Message>
    </Segment>
)

const FormIsLoading = () => (
    <Segment>
      <Message icon>
      <Icon name='circle notched' loading />
      <Message.Content>
      <Message.Header>Hang on</Message.Header>
      <p>Loading data</p>
      </Message.Content>
      </Message>
    </Segment>
)

export { GenericFormStore, SimpleFormInput, FormInput, Configurator, FormSetupError, FormIsLoading }
