import L from 'leaflet';
import { createPathComponent } from '@react-leaflet/core';

let bringToFrontTimer;

const createTrail = (props, context) => {
  const instance = new L.Polyline(props.trail.length > 1 ? props.trail : [], {
    smoothFactor: 5,
    color: '#4292c6',
  });

  return { instance, context: { ...context, overlayContainer: instance } };
};

const updateTrail = (instance, props) => {
  if (!props.trail || !props.trail.length) {
    return;
  }
  instance.setLatLngs(props.trail);

  if (!bringToFrontTimer) {
    bringToFrontTimer = setTimeout(() => {
      bringToFrontTimer = null;
    }, 2000);
    instance.bringToFront();
  }
};

const Trail = createPathComponent(createTrail, updateTrail);

export { Trail };
