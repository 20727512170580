import React, { useRef, useEffect} from 'react';
import axios from 'axios'
import './CorrelatorGraph.css'
import { range } from 'd3-array'
import { extent, min, max } from 'd3-array'
import { scaleLinear } from 'd3-scale'
import { axisLeft, axisBottom, axisTop } from 'd3-axis'
import { select } from 'd3-selection'
import { createMedia } from "@artsy/fresnel"
import { Popup, Icon, Input, Radio, Form, Divider } from 'semantic-ui-react'

const transducer_colors = ["#0000ff", "#00ff00", "#ff0000", "#000000"]
const legendKeys = ["t1", "t2", "t3", "t4"];

const Axis = props => {
    // https://stackoverflow.com/a/56029853
    const axisRef = axis => {
        axis && props.axisCreator(select(axis));
    };
    return <g className="axis" ref={axisRef} />;
};

const GraphGrid = props => {
    // https://stackoverflow.com/a/56029853
    const axisRef = axis => {
        axis && props.axisCreator(select(axis));
    };
    return <g className="graph-grid" ref={axisRef} />;
}; 

const GridLayoutPopup = props => {

    const onHandleEvent = props.setGraphAxisType

    const onYMnValueChange = (evt, data) => {
        props.setCustomYMin(data.value)
    }

    const onYMxValueChange = (evt, data) => {
        props.setCustomYMax(data.value)
    }

    const GridLayoutOptions = () => {
        return (
            <Form>
                <Form.Field>
                    Axis options
                </Form.Field>
                <Form.Field>
                <Radio
                        label="Auto"
                        name="radioGroup"
                        value="auto"
                        checked={props.graphDisplay  === "auto"}
                        onChange={onHandleEvent}
                    />
                </Form.Field>
                <Form.Field>
                <Radio
                        label="Fixed"
                        name="radioGroup"
                        value="fixed"
                        checked={props.graphDisplay  === "fixed"}
                        onChange={onHandleEvent}
                    />
                </Form.Field>
                <Divider/>
                <Form.Field>
                    <Input label="Y Max"
                        style={{width:"30%"}}
                        size="mini"
                        defaultValue={props.customYMx}
                        onChange={onYMxValueChange}
                        disabled={props.graphDisplay !== "fixed"}
                    />
                </Form.Field>
                <Form.Field>
                    <Input label="Y Min"
                        style={{width:"30%"}}
                        size="mini"
                        defaultValue={props.customYMn}
                        onChange={onYMnValueChange}
                        disabled={props.graphDisplay !== "fixed"}
                    />
                </Form.Field>
            </Form>
        )
    }

    return (
        <Popup
            content={GridLayoutOptions}
            on='click'
            trigger={<Icon name='setting' style={{float: "right"}} color="grey"/>}
            position='top right'
        />
    )
}

const Legends = props => {

    if (props.mod === 0) return <></>

    // All base values for outerWidth = 1500, outerHeight = 600
    let legendTitleFont = 16 * props.mod
    let legendTextFont = 12 * props.mod
    let ltx = 32 * props.mod
    let lty = -10 * props.mod
    let circleR = 6 * props.mod
    let circleX = 40 * props.mod
    let circleY = 10 * props.mod
    let textX = 60 * props.mod
    let textY = 15 * props.mod
    let yDelta = 25 * props.mod

    return (
        <g width="13%" height="40%" transform={props.legendsTransform}>
            <text x={ltx} y={lty} style={{fontSize: legendTitleFont+'px', textDecorationLine: 'underline'}}>Transducer</text>
            <circle cx={circleX} cy={circleY} r={circleR} fill={transducer_colors[0]} />
            <text className="legendstext" x={textX} y={textY} style={{fontSize: legendTextFont+'px', fontWeight: 'bold'}}>{legendKeys[0]}</text>
            <circle cx={circleX} cy={circleY+yDelta}  r={circleR} fill={transducer_colors[1]} />
            <text className="legendstext" x={textX} y={textY+yDelta} style={{fontSize: legendTextFont+'px', fontWeight: 'bold'}}>{legendKeys[1]}</text>
            <circle cx={circleX} cy={circleY+(2*yDelta)} r={circleR} fill={transducer_colors[2]} />
            <text className="legendstext" x={textX} y={textY+(2*yDelta)} style={{fontSize: legendTextFont+'px', fontWeight: 'bold'}}>{legendKeys[2]}</text>
            <circle cx={circleX} cy={circleY+(3*yDelta)}  r={circleR} fill={transducer_colors[3]} />
            <text className="legendstext" x={textX} y={textY+(3*yDelta)} style={{fontSize: legendTextFont+'px', fontWeight: 'bold'}}>{legendKeys[3]}</text>
        </g>   
    )
};

const GraphInner = props => {

    const margin = {top: 20, right: 15, bottom: 30, left: 30};
    const outerWidth = props.width;
    const outerHeight = props.height;
    const width = outerWidth - margin.left - margin.right;
    const height = outerHeight - margin.top - margin.bottom;

    const legendsMarginTop = margin.top * 6 * props.mod;
    const legendsMarginRight = margin.right * 16 * props.mod;
    
    const canvasRef = useRef(null)
    const yMin = useRef(new Array(10))
    const yMax = useRef(new Array(10))

    const x_min = props.graph[0][0].px
    const x_max = props.graph[0][props.graph[0].length-1].px

    let _yMx = props.customYAxisMax || props.customYAxisMax === 0 || 100
    let _yMn = props.customYAxisMin || props.customYAxisMax === 0 || 50

    if (props.graphDisplay === "auto") {
        const y_extents = props.graph.map(item => {
            return extent(item, d => d.py)
        })
        const y_min = min(y_extents, d => d[0])
        const y_max = max(y_extents, d => d[1])

        yMax.current.shift(0)
        yMax.current.push(y_max)
        yMin.current.shift(0)
        yMin.current.push(y_min)

        _yMx = max(yMax.current)
        _yMn = min(yMin.current)

        // Gives the automatic graph some padding
        _yMx += 10
        _yMn -= 10
    }

    const xScale = scaleLinear()
        .domain([x_min, x_max])
        .range([0, width])
        .nice();

    const yScale = scaleLinear()
        .domain([_yMn, _yMx])
        //.domain([10, 100])
        .range([height, 0])
        .nice();

    useEffect(() => {
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')

        var nWidth = context.canvas.clientWidth;
        var nHeight = context.canvas.clientHeight;

        context.canvas.width = nWidth;
        context.canvas.height = nHeight;

        const drawPoint = (ctx, x, y) => {
            const px = xScale(x);
            const py = yScale(y);
            ctx.lineTo(px, py)
        }

        const draw = (ctx) => {
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

            if (!props.graph) {
                return
            }
            props.graph.forEach((tr, idx) => {
                ctx.beginPath()
                // ctx.moveTo(xScale(0),yScale(0))
                ctx.strokeStyle = transducer_colors[idx]
                ctx.lineWidth = 1
                tr.forEach(d => {
                    drawPoint(ctx, d.px, d.py)
                })
                ctx.stroke()
            })
        }

        draw(context)

    }, [props.graph, xScale, yScale])

    
    const xAxis = axisBottom(xScale)
    const yAxis = axisLeft(yScale)
    const xAxisTop = axisTop(xScale)
    const xTransform = `translate(${margin.left}, ${outerHeight - margin.bottom})`;
    const yTransform = `translate(${margin.left}, ${margin.top})`;
    const legendsTransform = `translate(${outerWidth - legendsMarginRight}, ${legendsMarginTop})`;

    const labelXTransform = `translate(${outerWidth/2 - 40}, ${outerHeight + 10}) `;
    const labelYTransform = `translate(-10, ${outerHeight/2 + 70}) rotate(-90)`;


    const gridX = axisBottom(xScale)
                    .tickSize(-height)
                    .tickFormat('')
                    
    const gridY = axisLeft(yScale)
                    .tickSize(-width)
                    .tickFormat('')

    return (
        <div>
            <GridLayoutPopup 
                graphDisplay={props.graphDisplay} 
                setGraphAxisType={props.setGraphAxisType}
                customYMn={props.customYAxisMin}
                customYMx={props.customYAxisMax}
                setCustomYMin={props.setCustomYMin}
                setCustomYMax={props.setCustomYMax}/>

            <Divider hidden />
            
            <div className="scatter-container" style={{width: outerWidth, height: outerHeight, marginBottom: "50px"}}>
                <canvas
                    className="canvas-plot"
                    ref={canvasRef}
                    style={{
                        width: outerWidth-margin.left,
                        height: outerHeight-margin.top,
                        marginLeft: margin.left + "px",
                        marginTop: margin.top + "px",
                    }}
                />
                <svg className="svg-plot"
                    width={outerWidth}
                    height={outerHeight}
                    style={{overflow:"visible"}}
                    
                    >
                    {props.labelX &&
                        <g transform={labelXTransform}>
                            <text >{props.labelX}</text>
                        </g>
                    }
                    {props.labelY &&
                        <g transform={labelYTransform}>
                            <text >{props.labelY}</text>
                        </g>
                    }
                    <g transform={xTransform} >
                        <Axis axisCreator={xAxis}/>
                        <GraphGrid axisCreator={gridX}/>
                    </g>
                    <g transform={yTransform}>
                        <Axis axisCreator={yAxis}/>
                        <GraphGrid axisCreator={gridY}/>
                    </g>
                    <g transform={yTransform}>
                        <Axis axisCreator={xAxisTop}/>
                    </g>
                    <g>
                        <Legends legendsTransform={legendsTransform} mod={props.mod}/>
                    </g>
                </svg>
            </div>
        </div>
    )
}


export function BucketSampler() {
    // https://github.com/d3fc/d3fc-sample/blob/master/src/bucket.js
    var bucketSize = 10;

    var bucket = (data) => bucketSize <= 1
        ? data.map((d) => [d])
        : range(0, Math.ceil(data.length / bucketSize))
            .map((i) => data.slice(i * bucketSize, (i + 1) * bucketSize));

    bucket.bucketSize = function(x) {
        if (!arguments.length) {
            return bucketSize;
        }

        bucketSize = x;
        return bucket;
    };
    return bucket
  }

function killInf(val) {
    // Remove negative infinity from the graph
    if (val < -1e9) {
        return NaN
    }
    return val
}


function downsample(payload, x_scale, y_scale, x_offset, y_offset) {
    // Downsample to 1 entry pr pixel in chart (if needed)
    //var dataPrPixel = payload.length / this.width // Downsample to number of pixels available (responsive)
    var dataPrPixel = payload.length / 500 // Downsample to fixed number of entries
    //dataPrPixel = 1;

    var r0 = payload.map(function(d) { return d[0] })
    var r1 = payload.map(function(d) { return d[1] })
    var r2 = payload.map(function(d) { return d[2] })
    var r3 = payload.map(function(d) { return d[3] })

    if (dataPrPixel > 1)  {
        // Downsample with BucketSampler
        var bs = BucketSampler()
        var buckets = bs.bucketSize(dataPrPixel)

        r0 = buckets(r0).map(function (d) { return Math.max(...d) })
        r1 = buckets(r1).map(function (d) { return Math.max(...d) })
        r2 = buckets(r2).map(function (d) { return Math.max(...d) })
        r3 = buckets(r3).map(function (d) { return Math.max(...d) })
    } else {
        // Use data without downsampling
        dataPrPixel = 1;
    }

    var geometry = [[], [], [], []]
    for (var index = 0; index < r0.length; index++) {
        const px = index * dataPrPixel * x_scale + x_offset
        geometry[0][index] = {px: px, py: y_offset + y_scale * killInf(r0[index])}
        geometry[1][index] = {px: px, py: y_offset + y_scale * killInf(r1[index])}
        geometry[2][index] = {px: px, py: y_offset + y_scale * killInf(r2[index])}
        geometry[3][index] = {px: px, py: y_offset + y_scale * killInf(r3[index])}
    }
    return geometry
}

class CorrelatorGraphFetcher extends React.Component {
    constructor() {
        super()
        this.state = {
            graph: [],
            x_scale: 1,
            y_scale: 1,
            error: "",
            graphDisplay: "fixed",
            customYAxisMin: 0,
            customYAxisMax: 100,
            x_display_name: "test1"

        }
        this.active = true
    }
    fetch = () => {
        const url = this.props.url || '/api/graph'
        axios.get(url)
        .then(response => {
            if (!this.active) {
                return
            }
            if (response.data.data === undefined) {
                console.log("Got no data")
                this.setState({error: "No data available"})
                return
            }
            const x_offset = response.data.x_offset || 0;
            const y_offset = response.data.y_offset || 0;
            const graph = downsample(response.data.data, response.data.x_scale, response.data.y_scale, x_offset, y_offset)
            this.setState({graph: graph, x_scale: response.data.x_scale, y_scale: response.data.y_scale, error: ""})
        })
        .catch(err => {
            console.log("Error", err)
            this.setState({graph: [], error: err.toString()})
        })

    }
    setGraphAxisType = (e, { value }) => { 
        this.setState({ graphDisplay: value }) }

    changeCustomAxisYMinValue = (value) => {
        this.setState({ customYAxisMin: value})
    }

    changeCustomAxisYMaxValue = (value) => {
        this.setState({ customYAxisMax: value})
    }

    componentDidMount() {
        this.fetch()
        this.interval = setInterval(this.fetch, 100)
        if (this.props.defYMin !== undefined) this.changeCustomAxisYMinValue(this.props.defYMin)
        if (this.props.defYMax !== undefined) this.changeCustomAxisYMaxValue(this.props.defYMax)
    }
    componentWillUnmount() {
        this.active = false
        clearInterval(this.interval)
    }
    render() {
        if (this.state.error) {
            return (
                <div>No data available</div>
            )
        }
        return (
            <div>
            {this.state.graph.length > 0 && (
                <Graph 
                    graph={this.state.graph} // The graph data itself
                    // VVV This block is for graph axis customisation
                    graphDisplay={this.state.graphDisplay} 
                    setGraphAxisType={this.setGraphAxisType}
                    customYAxisMin = {this.state.customYAxisMin}
                    customYAxisMax = {this.state.customYAxisMax}
                    setCustomYMax = {this.changeCustomAxisYMaxValue}
                    setCustomYMin = {this.changeCustomAxisYMinValue}
                    {...this.props}
                />
            )}
            </div>
        )
    }
}

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        sm: 0,
        md: 600,
        lg: 1024,
        xl: 1920,
    },
})

const Graph = props => {
    return ( 
        <MediaContextProvider>
            <Media at="sm">
                <GraphInner width={320} height={128} mod={0} {...props}/>
            </Media>
            <Media at="md">
                <GraphInner width={700} height={280} mod={0.5} {...props}/>
            </Media>
            <Media at="lg">
                <GraphInner width={1000} height={400} mod={0.8} {...props}/>
            </Media>
            <Media greaterThanOrEqual="xl">
                <GraphInner width={1500} height={600} mod={1} {...props}/>
            </Media>
        </MediaContextProvider>
    )
}

export default CorrelatorGraphFetcher
