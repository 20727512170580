import React, { useState } from 'react';
import { Popup, Button, Message } from 'semantic-ui-react';

function SendFactoryReset() {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return fetch('/api/v1/about/factoryreset', options);
}

const FactoryResetButton = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const reset = () => {
    setIsSubmitting(false);
    setIsSubmitSuccess(false);
    setSubmitError('');
  };

  const onClick = async () => {
    if (isSubmitting) {
      return;
    }
    reset();
    const result = await SendFactoryReset();
    if (!result.ok) {
      const body = await result.json();
      console.log('submit error', body);
      setSubmitError("Error "+body.error);
      setIsSubmitSuccess(false);
    } else {
      console.log('submit success');
      setIsSubmitSuccess(true);
      setSubmitError('');
      setTimeout(() => {
        setIsSubmitSuccess(false);
      }, 5000);
    }
    setIsSubmitting(false);
  };

  return (
    <Popup
      trigger={
        <Button type="button" name="cog" color="grey">
          Factory reset
        </Button>
      }
      content={
        <div>
          Warning: all settings, including network settings, will be lost! Are you sure? Will be applied when the DVL
          next boots.
          <Button
            color={submitError ? 'red' : isSubmitSuccess ? 'green' : 'grey'}
            onClick={onClick}
            name={'redo'}
            content="Confirm factory defaults"
          />
          <div className="top-padding">
            {submitError && <Message color="yellow" header="Failed to store" content={submitError} />}
          </div>
        </div>
      }
      on="click"
      position="bottom left"
      onOpen={reset}
    />
  );
};

export { FactoryResetButton };
