import React, { useState } from 'react';
import { Icon, Header, Button, Checkbox } from 'semantic-ui-react';
import { fetcher } from '../lib/fetch';
import useSWR, { mutate } from 'swr';
import { HelpPopup } from './HelpText';
import { ApplyGenericConfig } from './ConfigGeneric';

function ShowIfPeriodicCyclingIsDisabled() {
    const { data, error } = useSWR('/api/v1/config', fetcher, { refreshInterval: 5000, dedupingInterval: 100 });

    if (error) {
        console.warn('Unable to fetch periodic cycling');
        return null;
    }

    if (!data) {
        return null;
    }

    if (data.periodic_cycling_enabled !== undefined && !data.periodic_cycling_enabled) {
        return (
            <span>
                <Icon name="toggle off" title="Periodic cycling is disabled" />{' '}
                <HelpPopup text={'Periodic cycling is disabled'} />
            </span>
        );
    }

    return '';
}

function PeriodicCyclingSetting() {
    const { data, error } = useSWR('/api/v1/config', fetcher);
    if (error) {
        return <p>Error loading config</p>;
    }
    if (!data) {
        // We are loading, show nothing until loaded
        return null;
    }
    if (data.periodic_cycling_enabled === undefined) {
        return <p>Config failed to load</p>;
    }
    return <PeriodicCyclingSettingInner data={data} />;
}

function PeriodicCyclingSettingInner({ data }) {
    const [submitMessage, setSubmitMessage] = useState('');
    const [checked, setChecked] = useState(data.periodic_cycling_enabled);

    const submit = async () => {
        setSubmitMessage('');
        const result = await ApplyGenericConfig(
            data.speed_of_sound,
            data.acoustic_enabled,
            data.dark_mode_enabled,
            data.mounting_rotation_offset,
            checked
        );
        if (result.ok) {
            const body = await result.text();
            setSubmitMessage('Success: ' + body);
        } else {
            const body = await result.text();
            setSubmitMessage('Failed to submit (' + result.status + '): ' + (body ? body : 'empty reply'));
        }
        mutate('/api/v1/config');
    };

    const onChange = (e, data) => {
        setChecked(data.checked);
    };

    return (
        <div className="top-padding">
            <Header>Periodic cycling</Header>

            <div>
                <Checkbox toggle label="Periodic cycling enabled" onChange={onChange} checked={checked} />
            </div>

            <div className="top-padding">
                <Button content="Apply" active onClick={() => submit()} />
            </div>
            <div className="top-padding">{submitMessage}</div>
        </div>
    );
}

export { ShowIfPeriodicCyclingIsDisabled, PeriodicCyclingSetting };
