import React from 'react';
import { Table } from 'semantic-ui-react';
import { fetcher } from '../../lib/fetch';
import useSWR from 'swr';
import { formatDecimals } from '../../lib/format';

const TableRow = ({ title, children }) => (
    <Table.Row>
        <Table.Cell>{title}</Table.Cell>
        <Table.Cell>{children}</Table.Cell>
        <Table.Cell />
    </Table.Row>
);

const StatusView = ({ detailed = false, collapsing = false }) => {
    const { data, error } = useSWR('/api/v1/about/status', fetcher, { refreshInterval: 3000, dedupingInterval: 100 });
    if (error) {
        return <p>Error loading status</p>;
    }
    if (!data) {
        return <p>Loading..</p>;
    }
    const temperature = data.temperature ?? -1;
    const cpu_load = data.cpu_load ?? -1;
    const disk_free = Object.entries(data.disk_free) ?? [];
    return (
        <Table basic="very" collapsing={collapsing}>
            <Table.Header>
                <TableRow title="Temperature">
                    <strong>{formatDecimals(temperature, 1)}</strong>&nbsp;C
                </TableRow>

                {detailed && (
                    <>
                        <TableRow title="CPU Load">
                            <strong>{formatDecimals(cpu_load, 2)}</strong>&nbsp;
                        </TableRow>

                        {disk_free.map(([name, size]) => (
                            <TableRow
                                key={name}
                                title={
                                    <span>
                                        Disk free <strong>{name}</strong> partition
                                    </span>
                                }
                            >
                                <strong>{formatDecimals(size, 2)}</strong>&nbsp;GB
                            </TableRow>
                        ))}
                    </>
                )}
            </Table.Header>
        </Table>
    );
};

export default StatusView;
