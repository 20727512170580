import React from 'react';
import { Message, Popup, Button, Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { useStores } from '../../Stores/use-stores';

const ShowWarning = ({ message, color }) => (
  <Message
    icon="exclamation"
    style={{ backgroundColor: color, color: 'rgba(255,255,255,1)', paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
    content={<strong>{message}</strong>}
  />
);

// colors matching the semantic ui colors
const severityToColorMapping = {
  info: {name: "blue", code:"#2185d0"},
  warning: {name: "orange", code: '#f2711c'},
  error: {name: "red", code: '#f02101'},
};

const WarningMessages = observer(() => {
  const { warningMessageStore } = useStores();
  return <WarningMessagesView messages={warningMessageStore.allSorted} />;
});

const WarningMessagesView = ({messages}) => {
  const severityToColor = (severity) => {
    return severityToColorMapping[severity] || {name: "blue", code:'#2185d0'};
  };
  // Default everything is good.
  let buttonColor = "black"
  let buttonIcon = "check"
  let buttonTitle = "Operational"
  if (messages.length > 0) {
    // Messages are sorted with the most important message first
    const severity = messages[0].severity;
    const description = severity === "error" ? "Error" : "Warning"
    buttonColor = severityToColor(severity).name
    buttonTitle = `${description} ( ${messages.length} )`
    buttonIcon = <Icon name="bell" className='shake-animation'/>
  }
  return (
    <Popup
      trigger={<Button color={buttonColor} icon={buttonIcon} content={buttonTitle} />}
      content={
        <div style={{
          backgroundColor: 'rgba(255,255,255,0.3)',
          //zIndex: "19999 !important", /* put above notyf toast */
        }}>
          {messages.map((d) => (
            <ShowWarning
              key={d.id}
              message={d.message}
              color={severityToColor(d.severity).code}
              onDismiss={d.dismiss}
            />
          ))}
          {messages.length === 0 && (<p>No problems detected</p>)}
        </div>
      }
      on="click"
      position="bottom right"
      flowing
    />
  );
}

export { WarningMessages };
