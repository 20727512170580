import { observable, computed, decorate, action } from 'mobx'
import qte from 'quaternion-to-euler';
import axios from 'axios'

function rad2deg(rad) {
    let deg = 180 * rad / Math.PI
    if (deg < 0) {
        return (360 + deg)
    }
    if (deg > 360) {
        return (deg - 360)

    } else {
        return deg
    }
}

class OrientationStore {
    // Observable values
    pitch = 0
    roll = 0
    yaw = 0

    // Fetching state
    loadSuccess = false
    isLoading = false
    loadingError = ""
    isRunning = false

    // Computed values
    get pitchDeg() {
        return rad2deg(this.pitch)
    }

    get rollDeg() {
        return rad2deg(this.roll)
    }
    get yawDeg() {
        return rad2deg(this.yaw)
    }

    fetch() {
        if (!this.isRunning) { return false }
        this.isLoading = true
        //axios.get('/api/v1/orientation')
        axios.get("/api/v1/imu/")
        .then(response => {
            this.loadSuccess = true
            this.isLoading = false
            this.loadingError = ""
            if (false) {
                var euler = qte([response.data.x, response.data.y, response.data.z, response.data.w]);
                this.pitch = euler[0] // this.rad2deg(euler[0])
                this.roll = euler[1] //this.rad2deg(euler[1])
                this.yaw = euler[2] //this.rad2deg(euler[2])
            } else {
                this.yaw = response.data.yaw
                this.pitch = response.data.pitch
                this.roll = response.data.roll
            }
            setTimeout(() => this.fetch(), 500)
        })
        .catch((e) => {
            this.loadSuccess = false
            this.isLoading = false
            this.loadingError = e.toString()
            //console.log(this.loadingError)
            setTimeout(() => this.fetch(), 2000)
        })
    }
    startFetching() {
        if (this.isRunning) {
            console.log("orientation store already running")
            return false
        }
        this.isRunning = true
        this.fetch()
    }
    stopFetching() {
        this.isRunning = false
    }
}

decorate(OrientationStore, {
    rollPitchYaw:observable,
    pitch: observable,
    roll: observable,
    yaw: observable,
    pitchDeg: computed,
    rollDeg: computed,
    yawDeg: computed,
    fetch: action,
    setFromWs:action
})

export default OrientationStore

