import React, { useState } from 'react';
import { Segment, Icon, Header, Form, Button, Popup, Message } from 'semantic-ui-react';
import { fetcher } from '../lib/fetch';
import useSWR, { mutate } from 'swr';
import useForm from '../lib/useForm';
import { ApplyGenericConfig } from '../Components/ConfigGeneric';
import { FactoryResetButton } from '../Components/FactoryResetButton';
import { ExpandableSettings } from '../Components/ExpandableSettings';
import NetworkConfig from '../Components/ConfigNetwork';
import TimeConfig from '../Components/ConfigTime';
import { RebootButton } from '../Components/RebootButton';
import { ButtonResetGyro } from '../Components/Map/Buttons';

async function checkStatus(response) {
  if (response.ok) {
    return response;
  } else {
    const text = await response.text();
    var error = new Error(response.status + ' ' + response.statusText + ' ' + text);
    error.response = response;
    return Promise.reject(error);
  }
}

function Config() {
  const { data: configData, error: configError } = useSWR('/api/v1/config', fetcher);

  if (configError) {
    console.log('Config load error', configError);
    return (
      <Message
        icon="exclamation"
        header="Cannot load configuration"
        content={'Lost connection to DVL? (' + configError.toString() + ')'}
      />
    );
  }

  if (!configData) {
    return <Icon name="spinner" loading />;
  }

  return (
    <div>
      <ConfigForm
        speedOfSound={configData.speed_of_sound}
        mountingOffset={configData.mounting_rotation_offset}
        darkModeEnabled={configData.dark_mode_enabled}
        acousticEnabled={configData.acoustic_enabled}
      />
      {
        <Segment>
          <Header as="h3">IMU</Header>
          <SetGyro />
        </Segment>
      }

      <Segment>
        <ExpandableSettings title="Advanced configuration">
          <NetworkConfig />

          <Segment>
            <Header as="h3">System time configuration</Header>
            <TimeConfig />
          </Segment>

          <Segment>
            <Header as="h3">Manage</Header>
            <FactoryResetButton />
            <RebootButton />
          </Segment>
        </ExpandableSettings>
      </Segment>
    </div>
  );
}

const SetGyro = () => {
  return (
    <ButtonResetGyro asButton={true}/>
  );
};

function HelpText({ help, header }) {
  return (
    <Popup
      style={{ whiteSpace: 'pre-wrap' }}
      trigger={<Icon name="question circle outline" />}
      content={help}
      header={header}
      on={['hover', 'click']}
    />
  );
}

function isNumberWithin(val, min, max) {
  if (isNaN(val)) {
    return false;
  }
  if (val < min) {
    return false;
  }
  if (val > max) {
    return false;
  }
  return true;
}

function validate(values) {
  let errors = {};
  if (!isNumberWithin(values.speedOfSound, 1000, 2000)) {
    errors.speedOfSound = 'Speed of sound must be between 1000 and 2000';
  }
  if (!isNumberWithin(values.mountingOffset, 0, 360)) {
    errors.mountingOffset = 'Mounting rotation offset must be between 0 and 360';
  }
  console.log('Validate', values, errors);
  return errors;
}

function ConfigForm({ speedOfSound, mountingOffset, darkModeEnabled, acousticEnabled }) {
  const onSubmit = (values) => {
    console.log('Submit', values);
    setSubmitError(false);
    setIsSubmitSuccess(false);
    setIsSubmitting(true);
    ApplyGenericConfig(values.speedOfSound, values.acousticEnabled, values.darkModeEnabled, values.mountingOffset)
      .then((r) => {
        setIsSubmitting(false);
        setIsSubmitSuccess(true);
        setTimeout(() => {
          setIsSubmitSuccess(false);
          mutate('/api/v1/config');
        }, 1000);
        if (r.status === 503) {
          // Simulated
          setSubmitError('Not supported in simulation mode');
        } else {
          return checkStatus(r);
        }
      })
      .catch((e) => {
        console.log('Submit error', e, e.response);
        setIsSubmitting(false);
        setSubmitError(e.toString());
        mutate('/api/v1/config');
      });
  };

  const initial = {
    speedOfSound,
    mountingOffset,
    darkModeEnabled,
    acousticEnabled,
  };
  const { values, errors, handleChange, handleSubmit } = useForm(onSubmit, validate, initial);
  const [submitError, setSubmitError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const onChange = (e) => {
    handleChange(e.target.name, e.target.value);
  };

  const onChangeCheckBox = (e, val) => {
    const { name, checked } = val;
    handleChange(name, checked);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Segment>
        <Header as="h3">Acoustics</Header>
        <Form.Group widths="equal">
          <Form.Input
            name="speedOfSound"
            error={errors.speedOfSound}
            value={values.speedOfSound}
            type="number"
            onChange={onChange}
            label={
              <p>
                Speed of sound{' '}
                <HelpText help="Speed of sound in the environment (sea water, oil, etc) in which the DVL will be used. Setting this precisely will improve the accuracy of the DVL's output. Default: 1500m/s." />
              </p>
            }
          />
        </Form.Group>

        <Form.Group>
          <Form.Checkbox
            checked={values.acousticEnabled}
            name="acousticEnabled"
            onChange={onChangeCheckBox}
            label="Acoustics enabled"
            toggle
          />
          <HelpText help="Enable or disable the sending of acoustic waves from the DVL's transducers. When disabled, no output will be able to be obtained. Can for example be used to save power, or to slow down the heating up of the DVL in air." />
        </Form.Group>

        <Form.Group>
          <Form.Checkbox
            checked={values.darkModeEnabled}
            name="darkModeEnabled"
            onChange={onChangeCheckBox}
            label="Dark mode"
            toggle
          />
          <HelpText help="Disables the DVL's LED. Can be used to prevent the DVL from being observed, or to prevent optical disturbance." />
        </Form.Group>

        <Header as="h3">Mounting</Header>
        <Form.Group widths="equal">
          <Form.Input
            name="mountingOffset"
            error={errors.mountingOffset}
            value={values.mountingOffset}
            type="number"
            onChange={onChange}
            label={
              <p>
                Mounting rotation offset{' '}
                <HelpText help="Angle in the clockwise direction (in the x-y plane, i.e. around the z-axis) from the forward axis of the vehicle upon which the DVL is mounted to the forward axis of the DVL." />
              </p>
            }
          />
        </Form.Group>

        <Button icon positive type="submit" disabled={isSubmitting}>
          Apply&nbsp;
          {isSubmitting && <Icon name="circle notched" loading />}
          {isSubmitSuccess && <Icon name="checkmark" />}
        </Button>

        {submitError && <Message icon="exclamation" header="Unable to apply settings" content={submitError} />}
      </Segment>
    </Form>
  );
}

export default Config;
