import React from 'react';
import { Header, Icon, Popup } from 'semantic-ui-react'
import { PropTypes } from 'prop-types';

const HeaderWithHelp = ({heading, help, as}) => (
    <Header as={as || "h5"}>{heading}
        <HelpPopup text={help} size="mini"/>
    </Header>
)

HeaderWithHelp.propTypes = {
    heading: PropTypes.string.isRequired,
    help: PropTypes.string.isRequired,
    as: PropTypes.string,
}

const HelpPopup = ({text, size}) => (
    <Popup
        style={{ whiteSpace: 'pre-wrap' }}
        trigger={<Icon size={size} name="question circle outline"/>}
        content={text}
        on={["hover", "click"]}
    />
)

HelpPopup.propTypes = {
    text: PropTypes.string.isRequired,
    size: PropTypes.string,
}

export { HeaderWithHelp, HelpPopup }
