import { decorate, observable, action } from 'mobx';

class ConnectionStatusStore {
    // Observable values
    connected =  false
    error =  ""

    setConnected(is_connected) {
        this.connected = is_connected
    }
}

decorate(ConnectionStatusStore, {
    connected: observable,
    error: observable,
    setConnected: action,

})

export default ConnectionStatusStore
