import React, { useState } from 'react';
import { HashRouter as Router, Route, Link, Switch } from 'react-router-dom';
import { Menu, Icon } from 'semantic-ui-react';
import { Sidebar, Segment, Responsive } from 'semantic-ui-react';
import { Provider } from 'mobx-react';
import ErrorBoundary from './Components/ErrorBoundary';
import './App.css';
import 'semantic-ui-css-offline';
import 'notyf/notyf.min.css';
import Dashboard from './Routes/Dashboard';
import Diagnostic from './Routes/Diagnostic';
import Config from './Routes/Config';
import About from './Routes/About';
import Outputs from './Routes/Outputs';
import Collect from './Routes/Collect';
import Debug from './Routes/Debug';
import ProductNameAndVersion from './Components/ProductNameAndVersion';
import NetworkConfigStore from './Stores/NetworkConfig';
import { WarningMessages } from './Components/Warnings/WarningMessages';
import { UpdateAvailableIcon } from './Components/VersionInfo';
import { WaterLinkedLogoWithLedBlink } from './Components/LEDButton';
import WarnIMUReset from './Components/Warnings/WarnIMUReset';

const networkConfigStore = new NetworkConfigStore();


const App = () => {
  const [isFullScreen, setIsFullscreen] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const toggleVisibility = () => setMenuVisible(!menuVisible);
  const navigate = (e) => {
    setMenuVisible(false);
  };

  return (
    <Router>
      <Provider networkConfigStore={networkConfigStore}>
        <Menu
          secondary
          attached="top"
          style={{ backgroundColor: '#272727', display: isFullScreen ? 'none' : 'flex' }}
          inverted
          fluid
          height={'200px'}>
          <Menu.Item onClick={toggleVisibility}>
            <Icon name={false ? 'angle double left' : 'sidebar'} />
            <Responsive minWidth={650}>Menu &nbsp; </Responsive>
          </Menu.Item>

          <Menu.Item>
            <Responsive maxWidth={550}>
              <ProductNameAndVersion />
            </Responsive>
            <Responsive minWidth={550}>
              <ProductNameAndVersion include_title={true} />
            </Responsive>
          </Menu.Item>

          <Responsive maxWidth={760} style={{ marginLeft: 'auto' }}>
            <Menu.Item>
              <WarningMessages/>
            </Menu.Item>
          </Responsive>

          <Responsive minWidth={760} style={{ marginLeft: 'auto' }}>
            <Menu.Menu>
              <Menu.Item>
                <WarningMessages />
                <UpdateAvailableIcon />
                <WaterLinkedLogoWithLedBlink />
              </Menu.Item>
            </Menu.Menu>
          </Responsive>
        </Menu>
        <Sidebar.Pushable as={Segment} id="AppMain" style={{ minHeight: '100vh', border: 'none' }}>
          <Sidebar
            as={Menu}
            animation="overlay"
            width="thin"
            visible={menuVisible}
            icon="labeled"
            vertical
            inverted
            style={{ backgroundColor: '#121212', zIndex: 2000 }}>
            <Menu.Item>
              <Link onClick={navigate} to="/">
                <Icon name="tachometer alternate" />
                Dashboard
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link onClick={navigate} to="/outputs">
                <Icon name="arrow right" />
                Outputs
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link onClick={navigate} to="/config">
                <Icon name="cogs" />
                Configuration
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link onClick={navigate} to="/diagnostic">
                <Icon name="bar chart" />
                Diagnostic
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link onClick={navigate} to="/collect">
                <Icon name="box" />
                Diagnostic report
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link onClick={navigate} to="/about">
                <Icon name="heartbeat" />
                About
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="#">
                <Icon name="angle double left" onClick={navigate} />
              </Link>
            </Menu.Item>
          </Sidebar>
          {/* <Sidebar.Pusher style={{width: this.state.menuVisible ? "calc(100% - 150px)" : "100%"}}> */}
          <Segment basic>
            <ErrorBoundary>
              <WarnIMUReset/>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={(props) => (
                    <Dashboard {...props} isFullScreen={isFullScreen} setIsFullscreen={setIsFullscreen} />
                  )}
                />
                <Route path="/outputs" component={Outputs} />
                <Route path="/config" component={Config} />
                <Route path="/diagnostic" component={Diagnostic} />
                <Route path="/collect" component={Collect} />
                <Route path="/about" component={About} />
                <Route path="/debug" component={Debug} />
              </Switch>
            </ErrorBoundary>
          </Segment>
          {/* </Sidebar.Pusher> */}
        </Sidebar.Pushable>
      </Provider>
    </Router>
  );
};

export default App;
