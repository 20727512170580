import React, { useState } from 'react';
import { Message, Icon } from 'semantic-ui-react';
import { fetcher } from '../../lib/fetch';
import useSWR from 'swr';
import { ButtonResetGyro } from '../Map/Buttons';

function WarnIMUReset() {
    const [visible, setVisible] = useState(true)
    const { data, error } = useSWR('/api/v1/imu/reset_gyros', fetcher);
    if (!data) {
        return null;
    }
    if (error) {
        return null;
    }
    if (!visible) {
        return null;
    }
    if (data.need_reset) {
        return (
            <Message info icon onDismiss={() => setVisible(false)}>
                <Icon name="exclamation" />
                <Message.Content>
                    <Message.Header>Improve dead reckoning performance</Message.Header>
                    Reset IMU drift compensation for optimum dead reckoning performance.
                    <br/>
                    <ButtonResetGyro asButton={true}/>
                </Message.Content>
            </Message>
        );
    }
    return null;
}

export default WarnIMUReset;
