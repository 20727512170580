import React from 'react';
import { icon } from 'leaflet'
import { Marker, Popup } from 'react-leaflet'
import { PropTypes } from 'prop-types';

var origoIcon = icon({
    iconUrl: '/record-circle-outline.svg',
    iconSize: [30, 30],
    iconAnchor: [15, 15],
    
})

const OrigoMarker = ({position, description}) => {
    return (
        <Marker position={position} icon={origoIcon}><Popup><span>{description}</span></Popup></Marker>
)}

OrigoMarker.propTypes = {
    position: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    description: PropTypes.string.isRequired,
}


export { OrigoMarker }
