import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { MapContainer, ScaleControl, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useStores } from '../../Stores/use-stores';
import DeadReckoningStatus from './DeadReckoningStatus';
import { OrigoMarker } from './Marker';
import { AcousticGrid } from './Grid';
import { Position } from './Position';
import { Coordinates } from './Coordinates';
import { Buttons } from './Buttons';

// outside returs true if value is less than -bounds or larger than bounds
const outside = (num, bounds) => num < -bounds || num > bounds;

const PositionMap = observer(({ isFullScreen, setIsFullscreen }) => {
  const { connectionStatusStore, trailStore } = useStores();
  const { connected } = connectionStatusStore;
  const { resetTrail, trailSeconds, setTrailLength } = trailStore;

  const [map, setMap] = useState(null);
  const [center, setCenter] = useState([0, 0]);
  const [zoom, setZoom] = useState(8);
  const [gridSize, setGridSize] = useState(1);
  const [gridRange, setGridRange] = useState({ x: { min: -5, max: 5 }, y: { min: -5, max: 5 } });

  const setCenterBounds = (pos) => {
    // Avoid crashing leaflet if the position is far away
    if (pos && pos.length && pos.length === 2) {
      const outOfBounds = outside(pos[0], 1e5) || outside(pos[1], 1e5)
      if (!outOfBounds) {
        setCenter(pos)
      } else {
        console.log("position out of bounds, not setting center")
      }
    }
  }

  useEffect(() => {
    if (localStorage.getItem('center')) {
      try {
        const parsed = JSON.parse(localStorage.getItem('center'));
        if (parsed && parsed.length && parsed.length === 2) {
          setCenterBounds(parsed);
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (localStorage.getItem('zoom')) {
      try {
        const parsed = parseInt(localStorage.getItem('zoom'));
        if (parsed > 0) {
          setZoom(parsed);
        }
      } catch (err) {
        console.log('ERR', err);
      }
    }
    if (localStorage.getItem('gridSize')) {
      try {
        const parsed = parseFloat(localStorage.getItem('gridSize'));
        if (parsed > 0) {
          setGridSize(parsed);
        }
      } catch (err) {
        console.log('ERR', err);
      }
    }
  }, [map]);

  useEffect(() => {
    if (map) {
      const { _southWest, _northEast } = map.getBounds();
      const range = {
        x: { min: _southWest.lat, max: _northEast.lat },
        y: { min: _southWest.lng, max: _northEast.lng },
      };
      setGridRange(range);
      map.setView(center, zoom);
    }
  }, [map, center, zoom]);

  return (
    <>
      <MapContainer
        whenCreated={setMap}
        style={{ width: '100%', height: '700px' }}
        center={center}
        zoom={zoom}
        maxZoom={11}
        crs={L.CRS.Simple}>
        <Buttons
          map={map}
          trailSeconds={trailSeconds}
          resetTrail={resetTrail}
          setTrailLength={setTrailLength}
          isFullScreen={isFullScreen}
          setIsFullscreen={setIsFullscreen}
          gridSize={gridSize}
          setGridSize={setGridSize}
        />

        <AcousticGrid gridSize={gridSize} range={gridRange} />
        <OrigoMarker position={[0, 0]} description="Origin/Zero point" />
        <Position connected={connected} />

        <ScaleControl position="bottomright" />
        {isFullScreen && <DeadReckoningStatus />}
        <Coordinates />
        <MapEvents setGridRange={setGridRange} setCenter={setCenter} />
      </MapContainer>
    </>
  );
});

const MapEvents = ({ setGridRange }) => {
  const updateGrid = () => {
    const zoom = mapEvents.getZoom();
    const { lat, lng } = mapEvents.getCenter();
    const { _southWest, _northEast } = mapEvents.getBounds();
    const range = { x: { min: _southWest.lat, max: _northEast.lat }, y: { min: _southWest.lng, max: _northEast.lng } };
    localStorage.setItem('center', JSON.stringify([lat, lng]));
    localStorage.setItem('zoom', zoom);
    setGridRange(range);
  };

  const mapEvents = useMapEvents({
    zoomend: () => {
      updateGrid();
    },
    moveend: () => {
      updateGrid();
    },
    resize: () => {
      updateGrid();
    },
  });

  return null;
};

export default PositionMap;
