import React, { useState, useEffect } from 'react';
import { Button, Modal, Icon, Dropdown } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { useStores } from '../../Stores/use-stores';
import Fullscreen from 'react-leaflet-fullscreen-plugin';
import { mutate } from 'swr';
import toastMaster from '../../Toast';
import { HelpPopup } from '../HelpText';

const Buttons = ({
  map,
  trailSeconds,
  resetTrail,
  setTrailLength,
  isFullScreen,
  setIsFullscreen,
  gridSize,
  setGridSize,
}) => (
  <>
    <ButtonFullscreen setIsFullscreen={setIsFullscreen} />

    <Button.Group
      vertical
      basic
      style={{
        zIndex: 100000,
        position: 'absolute',
        right: '25px',
        top: '10px',
        width: '140px',
        backgroundColor: 'rgba(256, 256, 256, 0.7)',
      }}>
      <ButtonPanMap label="Center" title="Center map on DVL" iconName="location arrow" map={map} />
      <ButtonResetFusion map={map} resetTrail={resetTrail} />
      <Dropdown
        icon="ellipsis horizontal"
        className="icon right labeled button"
        style={{ textAlign: 'center' }}
        text="More">
        <Dropdown.Menu>
          <ButtonTrail trailSeconds={trailSeconds} setTrailLength={setTrailLength} resetTrail={resetTrail} />
          <ButtonGrid gridSize={gridSize} setGridSize={setGridSize} />
          <ButtonResetGyro isFullScreen={isFullScreen} setIsFullscreen={setIsFullscreen} />
        </Dropdown.Menu>
      </Dropdown>
    </Button.Group>
  </>
);

const ButtonFullscreen = ({ setIsFullscreen }) => {
  const fullscreenIcon = '<i aria-hidden="true" class="expand icon large fitted"></i>';

  const fullscreenOptions = {
    position: 'topleft', // change the position of the button can be topleft, topright, bottomright or bottomleft, default topleft
    content: fullscreenIcon, // change the content of the button, can be HTML, default null
    //forcePseudoFullscreen: true, // force use of pseudo full screen even if full screen API is available, default false
  };
  return (
    <>
      {navigator.userAgent.indexOf('iPhone') < 0 && (
        <Fullscreen
          eventHandlers={{
            enterFullscreen: (event) => {
              event.target.fullscreenControl.link.firstElementChild.classList.remove('expand');
              event.target.fullscreenControl.link.firstElementChild.classList.add('compress');
              setIsFullscreen(true);
              //event.target._container.requestFullscreen()
            },
            exitFullscreen: (event) => {
              event.target.fullscreenControl.link.firstElementChild.classList.remove('compress');
              event.target.fullscreenControl.link.firstElementChild.classList.add('expand');
              //event.target._container.classList.remove("")
              // if(document.fullscreenElement) {
              //     document.exitFullscreen()
              // }
              setIsFullscreen(false);
            },
          }}
          {...fullscreenOptions}
        />
      )}
    </>
  );

  // const [iconName, setIconName] = useState("expand")

  // return (
  //     <Button
  //         title="Show map in fullscreen"
  //         icon
  //         color="grey"
  //         labelPosition='right'
  //         onClick={()=>{
  //             if(handle.active) {
  //                 handle.exit()
  //                 setIconName("expand")
  //             }
  //             else {
  //                 handle.enter()
  //                 setIconName("compress")
  //             }
  //         }}
  //         >
  //         <Icon fitted name={iconName} color="black"/>
  //         {handle && handle.active? "Exit fullscreen": "Fullscreen"}
  //     </Button>
  // )
};
const ButtonPanMap = observer(({ label, title, iconName, map }) => {
  const { fusionStore } = useStores();
  const { positionLocal } = fusionStore;

  const setPosition = () => {
    map.panTo(positionLocal);
  };

  return (
    <Button onClick={setPosition} icon color="grey" labelPosition="right" title={title}>
      <Icon name={iconName} color="black" />
      {label}
    </Button>
  );
});
const ButtonResetFusion = ({ map, resetTrail }) => {
  const onClick = () => {
    fetch('/api/positioning/reset', { method: 'POST' }).then((r) => {
      resetTrail();
      map.panTo({ lat: 0, lng: 0 });
    });
  };
  return (
    <Button onClick={onClick} icon color="grey" labelPosition="right" title="Restart dead reckoning run (set zero point)">
      <Icon name="sync" color="black" />
      Reset
    </Button>
  );
};

const ButtonResetGyro = ({ isFullScreen, asButton }) => {
  const [open, setOpen] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);

  const doResetGyros = () => {
    setSubmitInProgress(true);
    fetch('/api/v1/imu/reset_gyros', { method: 'POST' })
    .then((res) => {
      setSubmitInProgress(false);
      if (res.status !== 200) {
        //alert('Could not communicate with dvl');
        toastMaster.open({
          type: "error",
          message: "Failed performing gyro compensation. Code: "+res.status,
          duration: 3000,
        })
        return;
      }
      mutate('/api/v1/imu/reset_gyros')
      setOpen(false);
      toastMaster.open({
        type: "success",
        message: "Gyro compensation performed successfully.",
        duration: 3000,
      })
    })
    .catch((err) => {
      //alert('Could not communicate with dvl');
      toastMaster.open({
        type: "error",
        message: "Failed performing gyro compensation. No communication with DVL.",
        duration: 3000,
      })
      console.log('error', err);
      setSubmitInProgress(false);
    })
  }

  const trigger = asButton ? (
    <Button onClick={() => setOpen(true)}>Calibrate gyro</Button>
  ) : (
  <Dropdown.Item
    onMouseDown={() => {
      isFullScreen && document.exitFullscreen();
      setOpen(true);
    }}>
    Calibrate gyro
  </Dropdown.Item>
  )
  return (
    <Modal
      size={'tiny'}
      open={open}
      trigger={
        trigger
      }>
      <Modal.Header>Compensate and save gyro drift
        <HelpPopup text={'We do recommend that the gyro bias be re-calibrated for each new operational deployment of the DVL for improved dead reckoning performance.'}/>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {/* <Header>Stationary</Header> */}
          <p>Ensure that DVL is not moving.</p>
          <p>Ready?</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)} disabled={submitInProgress}>
          Cancel
        </Button>
        <Button
          content="Compensate"
          labelPosition="right"
          icon="checkmark"
          onClick={doResetGyros}
          disabled={submitInProgress}
          loading={submitInProgress}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};
const ButtonTrail = ({ trailSeconds, setTrailLength, resetTrail }) => {
  const [menuText, setMenuText] = useState('');

  const clearTrail = () => {
    resetTrail();
  };
  useEffect(() => {
    setMenuText(
      parseInt(trailSeconds) === 0
        ? `Trailing is off`
        : trailSeconds < 60
        ? `Trail ${trailSeconds} sec`
        : trailSeconds < 3600
        ? `Trail ${trailSeconds / 60} min`
        : trailSeconds < 999999
        ? `Trail ${trailSeconds / 3600} hour`
        : 'Trail unlimited'
    );
  }, [trailSeconds]);
  return (
    <Dropdown item text={menuText} simple pointing="top right">
      <Dropdown.Menu style={{ right: '50px' }}>
        {trailSeconds > 0 && (
          <>
            <Dropdown.Item text="Clear trail" onMouseDown={clearTrail} />
            <Dropdown.Divider />
          </>
        )}
        <Dropdown.Item text="Limit to 10 sec" onMouseDown={() => setTrailLength(10)} />
        <Dropdown.Item text="Limit to 3 min" onMouseDown={() => setTrailLength(180)} />
        <Dropdown.Item text="Limit to 1 hour" onMouseDown={() => setTrailLength(3600)} />
        <Dropdown.Item text="Trail unlimited" onMouseDown={() => setTrailLength(999999)} />
        <Dropdown.Divider />
        <Dropdown.Item text="Turn trailing off" onMouseDown={() => setTrailLength(0)} />
      </Dropdown.Menu>
    </Dropdown>
  );
};
const ButtonGrid = ({ setGridSize, gridSize }) => {
  const [menuText, setMenuText] = useState('');
  useEffect(() => {
    setMenuText(parseFloat(gridSize) === 0 ? `Grid is off` : `Grid ${gridSize} m`);
    localStorage.setItem('gridSize', gridSize);
  }, [gridSize]);

  return (
    <Dropdown item text={menuText} simple pointing="top right">
      <Dropdown.Menu style={{ right: '50px' }}>
        <Dropdown.Item text="Grid off" onMouseDown={() => setGridSize(0)} />
        <Dropdown.Item text="0.1 meter" onMouseDown={() => setGridSize(0.1)} />
        <Dropdown.Item text="1 meter" onMouseDown={() => setGridSize(1)} />
        <Dropdown.Item text="5 meter" onMouseDown={() => setGridSize(5)} />
        <Dropdown.Item text="10 meter" onMouseDown={() => setGridSize(10)} />
        <Dropdown.Item text="50 meter" onMouseDown={() => setGridSize(50)} />
        <Dropdown.Item text="100 meter" onMouseDown={() => setGridSize(100)} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export { Buttons, ButtonResetGyro };
