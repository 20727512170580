import { Notyf } from 'notyf';

/*
Toast is a popup notification shown to the user.

Setup the Toast and all the supported classes.
*/
export default new Notyf({
    duration: 0,
    position: {x: 'center', y: 'top'},
    dismissible: true,
    ripple: true,

    types: [
      {
        type: 'success',
        background: '#21ba45', // green
        icon: false,
        // icon: {
        //   className: 'check circle icon',
        //   tagName: 'i',
        //   text: ''
        // }
      },
      {
        type: 'info',
        background: '#2185d0', // blue
        icon: false,
        // icon: {
        //   className: 'check circle icon',
        //   tagName: 'i',
        //   text: ''
        // }
      },
      {
          type: 'warning',
          background: '#f2711c', // orange
          icon: false,
          // icon: {
          //   className: 'exclamation icon',
          //   tagName: 'i',
          //   text: ''
          // }
        },
        {
          type: 'error',
          background: '#db2828',  // red
          icon: false,
          // icon: {
          //   className: 'exclamation icon',
          //   tagName: 'i',
          //   text: ''
          // }
        }
      ],
})
