import React, { useEffect, useRef } from 'react';
import { divIcon } from 'leaflet';
import { Marker, Popup, Circle } from 'react-leaflet';
import { PropTypes } from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import { Trail } from './Trail';
import { observer } from 'mobx-react';
import { useStores } from '../../Stores/use-stores';

const Position = observer(({ connected }) => {
  const { fusionStore, trailStore } = useStores();
  const { rollPitchYaw, positionLocal, positionLocalStd } = fusionStore;
  const { reducedTrail } = trailStore;

  return (
    <>
      <StdCircle center={positionLocal} connected={connected} std={positionLocalStd} />
      <Trail trail={reducedTrail} />
      <DvlMarker position={positionLocal} direction={parseInt(rollPitchYaw[2])} />
    </>
  );
});

const StdCircle = ({ center, connected, std }) => {
  const circleRef = useRef();

  useEffect(() => {
    circleRef && circleRef.current && circleRef.current.bringToFront();
  });

  const circleSize = std > 1000 ? 1000 : std; // Clamp at 1000m
  return (
    <>
      {connected && <Circle ref={circleRef} center={center} radius={circleSize} color={'green'} />}
      {!connected && <Circle ref={circleRef} center={center} radius={10} color={'red'} />}
    </>
  );
};

const dvlIcon = (direction) => {
  const iconSize = [50, 50];
  const style = {
    transform: 'rotate3d(0,0,1, ' + direction + 'deg) translateY(-10px)',
    width: iconSize[0],
    height: iconSize[1],
  };
  return divIcon({
    className: 'custom-icon',
    iconSize: iconSize,
    html: ReactDOMServer.renderToString(<img alt="dvl" src="/direction-arrow-top.svg" style={style} />),
  });
};

const DvlMarker = ({ position, direction, description }) => (
  <Marker position={position} icon={dvlIcon(direction)} zIndexOffset={50}>
    <Popup>
      <span>{description}</span>
    </Popup>
  </Marker>
);

Position.propTypes = {
  position: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  description: PropTypes.string,
};

export { Position };
