
import React from 'react';
import {Icon} from 'semantic-ui-react'

const Coordinates = () => {
    return (
    <div style={{zIndex: 1000, position: "absolute", bottom: 0, width:"80px", height: "80px", color: "grey"  }}>
        <span style={{position: "relative", left: "9px", bottom: 0, fontSize:"20px"}}>x</span>
        <span style={{position: "relative", left: "48px", bottom: "-49px", fontSize:"20px"}}>y</span>
        <Icon name="long arrow alternate up" size='huge' style={{position: "relative", left: "-15px", bottom: "5px"}}></Icon><br/>
        <Icon name="long arrow alternate right" size='huge' style={{position: "relative", left: "3px", bottom: "35px"}}></Icon>
    </div>
)}



export { Coordinates }

