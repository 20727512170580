import fetch from 'unfetch';

async function ApplyGenericConfig(
    speedOfSound,
    acousticEnabled,
    darkModeEnabled,
    mountingOffset,
    periodicCyclingEnabled = undefined
) {
    const data = {
        speed_of_sound: Number(speedOfSound),
        acoustic_enabled: acousticEnabled,
        dark_mode_enabled: darkModeEnabled,
        mounting_rotation_offset: Number(mountingOffset),
    };
    if (periodicCyclingEnabled !== undefined) {
        data.periodic_cycling_enabled = periodicCyclingEnabled;
    }
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    };
    console.log('ApplyGenericConfig', data);
    return fetch('/api/v1/config', options);
}

export { ApplyGenericConfig };
