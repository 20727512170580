import React from 'react';
import { Table } from 'semantic-ui-react'
import { formatDecimals } from '../../lib/format';
import { observer } from 'mobx-react'
import { useStores } from '../../Stores/use-stores';




const DeadReckoningDetails = ({rollPitchYaw, position, positionStd, velocity, velocityStd}) => (
    <>
    <Table unstackable basic="very" compact="very" size="small"
        // style={{paddingTop: "1em", paddingLeft: "2em", paddingRight: "2em"}}
        style={{zIndex: 1000, position: "absolute", right: "12px", bottom: "70px", backgroundColor: "rgba(256, 256, 256, 0.7)", width:"270px", padding:"10px" }}
        >
    <Table.Body>

    {/* <Table.Row textAlign="right">
        <Table.Cell colSpan={2}>
            X
        </Table.Cell>
        <Table.Cell>Y
        </Table.Cell>
        <Table.Cell >Down
        </Table.Cell>
        <Table.Cell colSpan={2} textAlign={"center"}>
        FOM <HelpPopup text={"Figure of merit/accuracy."}/> 
        </Table.Cell>
    </Table.Row> */}

    <Table.Row textAlign="right">
        <Table.Cell textAlign="left">
        Speed 
        </Table.Cell>
        <Table.Cell textAlign="right">
        <strong> {formatDecimals(velocity[0], 3)} </strong>
        </Table.Cell>
        <Table.Cell textAlign="right">
        <strong> {formatDecimals(velocity[1], 3)} </strong>
        </Table.Cell>
        <Table.Cell textAlign="right">
        <strong> {formatDecimals(velocity[2], 3)} </strong>
        </Table.Cell>
        <Table.Cell textAlign="right">
            <strong> {formatDecimals(velocityStd, 3)}</strong>
        </Table.Cell>
        <Table.Cell textAlign="left" style={{paddingLeft:0}}>
        m/s
        </Table.Cell>
    </Table.Row>

    <Table.Row textAlign="right">
        <Table.Cell textAlign="left">
        Position 
        </Table.Cell>
        <Table.Cell textAlign="right">
        <strong> {formatDecimals(position[0], 3)} </strong>
        </Table.Cell>
        <Table.Cell textAlign="right">
        <strong> {formatDecimals(position[1], 3)} </strong>
        </Table.Cell>
        <Table.Cell textAlign="right">
        <strong> {formatDecimals(position[2], 3)} </strong>
        </Table.Cell>
        <Table.Cell>
            <strong> {formatDecimals(positionStd, 3)}</strong>
        </Table.Cell>
        <Table.Cell textAlign="left" style={{paddingLeft:0}}>
        m
        </Table.Cell>
    </Table.Row>

    <Table.Row textAlign="right">
        <Table.Cell colSpan={6}>&nbsp;</Table.Cell>
    </Table.Row>



    <Table.Row textAlign="right">
        <Table.Cell singleLine textAlign="left" style={{border: "none"}}>
        Orentation 
        </Table.Cell>
        <Table.Cell textAlign="right" style={{border: "none"}}>
        <strong> {formatDecimals(rollPitchYaw[0], 1)} </strong> 
        </Table.Cell>
        <Table.Cell textAlign="right" style={{border: "none"}}>
        <strong> {formatDecimals(rollPitchYaw[1], 1)} </strong>
        </Table.Cell>
        <Table.Cell textAlign="right" style={{border: "none"}}>
        <strong> {formatDecimals(rollPitchYaw[2], 1)} </strong>
        </Table.Cell>
        <Table.Cell textAlign="left" colSpan={2} style={{paddingLeft:0, border: "none"}}>
        &deg;
        </Table.Cell>
    </Table.Row>



    </Table.Body>
    </Table>
    {/* <Button.Group vertical basic style={{zIndex: 1000, position: "absolute", right: "25px", top: "10px", backgroundColor: "rgba(256, 256, 256, 0.7)" }}></Button.Group> */}
</>
)


const DeadReckoningStatus =  observer(() => {
     const { fusionStore }  = useStores()
     const { positionLocal, positionLocalStd, velocity, velocityStd, rollPitchYaw } = fusionStore

    return (
        <div>
        <DeadReckoningDetails rollPitchYaw={rollPitchYaw} position={positionLocal} positionStd={positionLocalStd} velocity={velocity} velocityStd={velocityStd}/>
    </div>
    )
})




export default DeadReckoningStatus
