import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react'

const ExpandableSettings = ({title, children}) => {
    const [open, setOpen] = useState(false);
    const toggleExpanded = () => setOpen(!open)
    const icon = open ? "angle double up" : "angle double down"
    return (
        <div>
            <h3 onClick={toggleExpanded}> {title} <Icon name={icon} /></h3>
            {open && (children)}
        </div>
    )
}

export { ExpandableSettings }
