import React, {useEffect, useState} from 'react';
import { Table, Icon } from 'semantic-ui-react'
import { formatDecimals } from '../lib/format.js'
import { PropTypes } from 'prop-types';
import { HelpPopup } from './HelpText'

const DopplerDetails = ({altitude, speed, fom, velocity_valid, fps}) => {
  const [validAltitude, setValidAltitude] = useState(altitude)
  const [validSpeed, setValidSpeed] = useState(speed)
  const [validFom, setValidFom] = useState(fom)


  useEffect(()=> {
    //Only display valid values
    //Format values
    if(velocity_valid) {
      setValidAltitude(formatDecimals(altitude, 2))
      setValidSpeed(formatDecimals(speed, 3))
      setValidFom(formatDecimals(fom, 4))
    }
  }, [velocity_valid, altitude, speed, fom])


  return (
    <Table basic="very" columns={2} style={{paddingTop: "1em", paddingLeft: "2em", paddingRight: "2em"}}>
    <Table.Body>

    <Table.Row>
      <Table.Cell>
        Speed
      </Table.Cell>
      <Table.Cell textAlign="right">
        <strong>{validSpeed}</strong>
      </Table.Cell>
      <Table.Cell textAlign="left" style={{paddingLeft:0}}>
      m/s
      </Table.Cell>

    </Table.Row>

    <Table.Row>
      <Table.Cell>
        Altitude
      </Table.Cell>
      <Table.Cell textAlign="right">
        <strong>{validAltitude}</strong>
      </Table.Cell>
      <Table.Cell textAlign="left" style={{paddingLeft:0}}>
        m
      </Table.Cell>

    </Table.Row>

    <Table.Row>
      <Table.Cell>
        Figure of merit <HelpPopup text={"A measure of the possible error in the calculated velocity"}/>
      </Table.Cell>
      <Table.Cell textAlign="right">
        <strong>{validFom}</strong>
      </Table.Cell>
      <Table.Cell textAlign="left" style={{paddingLeft:0}}>
        m/s
      </Table.Cell>
    </Table.Row>

    <Table.Row>
      <Table.Cell>
        Ping rate <HelpPopup text={"Rate at which velocity data is being outputted (depends upon altitude)"}/>
      </Table.Cell>
      <Table.Cell textAlign="right">
        <strong>{formatDecimals(fps, 0)}</strong>
      </Table.Cell>
      <Table.Cell textAlign="left" style={{paddingLeft:0}}>
        Hz
      </Table.Cell>
    </Table.Row>

    <Table.Row>
      <Table.Cell>
        Velocity valid
      </Table.Cell>
      <Table.Cell textAlign="right" colSpan={2}>
        {velocity_valid ? (
          <Icon title="Velocity is valid" color="green" name="check circle"/>
        ) : (
          <Icon title="Velocity is invalid" color="red" name="dont"/>
        )}
      </Table.Cell>
    </Table.Row>
    </Table.Body>
  </Table>

   )
}


DopplerDetails.propTypes = {
    altitude: PropTypes.number.isRequired,
    speed: PropTypes.number.isRequired,
    direction: PropTypes.number.isRequired,
    fom: PropTypes.number.isRequired,
    velocity_valid: PropTypes.bool.isRequired,
}

export default DopplerDetails
