import React, { Component } from 'react';
import { Segment, Icon, Header } from 'semantic-ui-react'
import releases from './releases'

const ReleaseItemList = ({items}) => (
    <ul>
        {items.map((d) => (<li key={d}>{d}</li>))}
    </ul>
)

class ReleaseNote extends Component {
    constructor(props) {
        super(props)
        this.state = {expanded: this.props.expanded}
    }
    toggleExpanded = (e) => {
        this.setState({expanded: !this.state.expanded})
    }
    render() {
        const { version, changes, knownissues } = this.props
        const icon = (this.state.expanded ? "angle double up" : "angle double down")
        return (
            <Segment>
               <Header as="h4" onClick={this.toggleExpanded}>Release {version} <Icon name={icon}/></Header>

                {this.state.expanded && (
                <div>
                <Header as="h5">Changes</Header>
                <ReleaseItemList items={changes}/>

                {knownissues.length > 0 && (
                    <div>
                    <Header as="h5">Known issues</Header>
                    <ReleaseItemList items={knownissues}/>
                    </div>
                )}
                </div>
                )}
            </Segment>
        )
    }
}

const ReleaseNotes = () => (
    <div>
        {releases.map((d, i) => (<ReleaseNote key={d.version} expanded={i === 0} {...d}/>))}
    </div>
)

export default ReleaseNotes;
