import { decorate, observable } from 'mobx';
import axios from 'axios'

class VersionStore {
    // Observable values
    hasChecked =  false
    isLatest =  true
    latestRelease =  ""
    url =  ""
    updateAvailable =  false
    loading =  true
    error =  ""

    fetch(chipid, version){
        //const baseUrl = "http://127.0.0.1:8080"
        const baseUrl = "https://update.waterlinked.com"
        axios.post(`${baseUrl}/api/v1/latest`, {
            chipid: chipid,
            current: version,
        })
        .then(response => {
            this.loading = false
            if (!response.status === 200) {
                throw Error(response)
            }
            return response.data
        })
        .then(data => {
            var url = data.url
            if (url[0] === "/") {
                url = baseUrl + url
            }
            this.isLatest = data.is_latest
            this.latestRelease = data.latest_release
            this.url = url
            this.loading = false
            this.error = ""
            this.hasChecked = true
            this.updateAvailable = this.hasChecked && !this.isLatest

        }).catch(err => {
            this.loading = false
            this.error = "Request failed " + err.toString()
            this.hasChecked = true
        })
    }
}

decorate(VersionStore, {
    hasChecked: observable,
    isLatest: observable,
    latestRelease: observable,
    url: observable,
    updateAvailable: observable,
    loading: observable,
    error: observable,

})

class AboutInfoStore {
    // Observable values
    version = "unknown"
    versionShort = "unknown"
    chipid = "unknown"
    isLoading = true
    versionStore = new VersionStore()

    // Fetching state
    loadSuccess = false
    loadingError = ""
    isRunning = false

    // Actions
    fetch() {
        if (!this.isRunning) { return false }
        this.isLoading = true
        axios.get('/api/v1/about')
        .then(response => {
            this.loadSuccess = false
            this.isLoading = false
            this.loadingError = ""
            this.chipid = response.data["chipid"]
            this.version = response.data["version"]
            this.versionShort = response.data["version_short"]
            this.versionStore.fetch(this.chipid, this.versionShort)
        })
        .catch(err => {
            this.loadSuccess = false
            this.isLoading = false
            this.loadingError = "Request failed " + err.toString()
            console.log("error: ", err)
            setTimeout(() => this.fetch(), 5000)
        })
    }

    startFetching() {
        if (this.isRunning) {
            console.log("already running")
            return false
        }
        this.isRunning = true
        this.fetch()
    }
    stopFetching() {
        console.log("stopping")
        this.isRunning = false
    }
}

decorate(AboutInfoStore, {
    chipid: observable,
    version: observable,
    versionShort: observable,
    isLoading: observable,
    versionStore: observable,

})

export default AboutInfoStore
