export default [
  {
    version: '2.5.2 (2024-05)',
    changes: [
      'Improve velocity calculation',
      'Fix issue that causes Ethernet connectivity problems with certain switches',
      'Fix network configuration incorrectly showing as "static" after factory reset',
      '[Beta] Add support for PD4 protocol endpoint'
    ],
    knownissues: [],
  },
  {
    version: '2.5.0 (2024-04)',
    changes: [
      'Beta release only',
      '[Beta] Add support for PD4 protocol endpoint',
      'Fix issue that causes Ethernet connectivity problems with certain switches',
    ],
    knownissues: [],
  },
  {
    version: '2.4.5 (2024-01)',
    changes: [
      'Reduce boot time when ethernet is not connected',
      'Fix bug where altitudes <0.2m can be unstable (bug introduced in 2.4.0)',
      'Add PD6 BS sentence (Bottom track, ship referenced velocity)',
      'Add storing gyro calibration automatically when using the API',
      'Change gyro calibration to be single button for calibrate and store in the GUI',
      'Change warning messages to include a popup in the GUI',
      'Improve temperature shutdown warning in GUI',
      'Fix minor upgrade issue affecting a minority of users',
    ],
    knownissues: [],
  },
  {
    version: '2.4.4 (2023-12)',
    changes: [
      'Fix bug where altitudes <0.2m can be unstable (bug introduced in 2.4.0)',
      'Add PD6 BS sentence (Bottom track, ship referenced velocity)',
      'Add storing gyro calibration automatically when using the API',
      'Change gyro calibration to be single button for calibrate and store in the GUI',
      'Change warning messages to include a popup in the GUI',
      'Improve temperature shutdown warning in GUI',
      'Fix minor upgrade issue affecting a minority of users',
    ],
    knownissues: [],
  },
  {
    version: '2.4.0 (2023-06)',
    changes: [
      'Add IMU compensation for fast velocity changes',
      'Improve accuracy of time of validity for altitudes >10m',
      'Improve dead reckoning position estimation when exposed to high acceleration',
      'Improve accuracy of altitude',
      'Improve accuracy of velocity valid',
      'Add ability to trigger pings though API',
      'Add configuration for periodic cycling',
      'Fix dead reckoning map clears when dead reckoning is reset using the API',
      'Fix NTP configuration persistent over reboots',
      'Fix velocities containing NaN or Inf not outputted to API/GUI',
      'Update serial protocol version to 2.5.0',
      'Update JSON protocol version to v3.1'
    ],
    knownissues: [],
  },
  {
    version: '2.3.2 (2023-02)',
    changes: [
      'Beta release only',
      'Add ability to trigger pings though API',
      'Add configuration for periodic cycling',
      'Fix dead reckoning map clears when dead reckoning is reset using the API',
      'Fix NTP configuration persistent over reboots',
      'Fix velocities containing NaN or Inf not outputted to API/GUI',
      'Update serial protocol version to 2.5.0',
      'Update JSON protocol version to v3.1'
    ],
    knownissues: [],
  },
  {
    version: '2.2.1 (2022-02)',
    changes: [
      'Add PD6 protocol support',
      'Add system time configuration',
      'Update serial protocol to version 2.4.0',
      'Fix missing line ending in JSON protocol',
      'Bugfixes'
    ],
    knownissues: [
      "NTP configuration is not persistent"
    ],
  },
  {
    version: '2.1.0 (2021-12)',
    changes: [
      'Add configuration over serial/TCP',
      'Add reset of dead reckoning over serial/TCP',
      'Add additional information in serial protocol (updated to 2.3.0)',
      'Add covariance to JSON protocol (updated to v3)',
      'Add timing information (time of validity, time_of_transmission)',
      'Improve GUI visuals',
      'Fix mounting rotation offset bug',
      'Change ethernet auto-negotiation to 10/100',
    ],
    knownissues: [],
  },
  {
    version: '2.0.8 (2021-08)',
    changes: ['Bugfixes'],
    knownissues: [],
  },
  {
    version: '2.0.2 (2021-06)',
    changes: [
      'Add thermal shut down',
      'Improve velocity calculation',
      'Update serial protocol to version 2.2.0',
      'Add output of orientation angels',
      'Add position estimation (dead reckoning) (Beta)',
    ],
    knownissues: [],
  },
  {
    version: '1.6.0 (2021-03)',
    changes: ['Improve range'],
    knownissues: [],
  },
  {
    version: '1.5.1 (2021-02)',
    changes: [
      'Improve velocity valid',
      'Improve noise immunity',
      'Add tranducer details in serial protocol',
      'Add vertical speed visualisation',
      'Add ping rate in GUI',
      'Improve web gui update rate using websockets',
    ],
    knownissues: [],
  },
  {
    version: '1.4.2 (2020-12)',
    changes: ['Fix bug in setting of fallback IP', 'Add hardware revision in API'],
    knownissues: [],
  },
  {
    version: '1.4.1 (2020-11)',
    changes: ['Fix instability introduced by 1.4.0'],
    knownissues: [],
  },
  {
    version: '1.4.0 (2020-11)',
    changes: ['Add factory reset', 'Fix TCP stops working after some time'],
    knownissues: [],
  },
  {
    version: '1.3.7 (2020-08)',
    changes: ['Add generation of diagnostic report'],
    knownissues: [],
  },
  {
    version: '1.3.5 (2020-06)',
    changes: ['Improve reliability of velocity data', 'GUI fixes'],
    knownissues: [],
  },
  {
    version: '1.3.3 (2020-06)',
    changes: ['Improve power control'],
    knownissues: [],
  },
  {
    version: '1.3.2 (2020-04)',
    changes: [
      'Higher speed supported on longer range',
      'Improved velocity valid',
      'Increased ping rate',
      'Add high temperature warning',
      'Update serial protocol to version 2.1.0',
    ],
    knownissues: [],
  },
  {
    version: '1.3.1 (2020-04)',
    changes: [
      'Initial public release',
      /*
            // Changes from 1.2.x (only relevant for early adopters)
            "Reduced minimum range to 0.05 m",
            "Increased speed in range 0.3-1.2 (up to 2.6 m/s horizontally)",
            "Significantly improved performance in tank and open water",
            "Improved velocity lock",
            "Improved performance on long distance",
            "Renamed message fields ('std' to 'fom', 'bottom_lock' to 'velocity_valid'", 'is_valid' to 'beam_valid')",
            "Add individual transducer information",
            "Improved reliability (read-only file-system)",
*/
    ],
    knownissues: [],
  },
  /*
    {
        version: "1.2.0 (2020-03-04)",
        changes: [
            "Add serial output",
            "Add TCP output",
        ],
        knownissues: [],
    },
    {
        version: "1.1.0 (2020-02-24)",
        changes: [
            "Add web GUI",
            "Add mDNS support",
        ],
        knownissues: [],
    },
    {
        version: "1.0.0 (2020-02-10)",
        changes: [
            "Initial release",
        ],
        knownissues: [],
    }
*/
];
