import React from 'react';
import { version } from '../../package.json';
import { fetcher } from '../lib/fetch';
import { capitalize } from '../lib/helpers';
import useSWR from 'swr';

export default function ProductNameAndVersion({include_title}) {
  const { data, error } = useSWR('/api/v1/about', fetcher);
  var variant = '';
  var productName = 'DVL';
  if (!error) {
    if (data) {
      productName = data.product_name;
      variant = capitalize(data.variant);
    }
  }

  return (
    <a href="/" className='rootlink'>
      {include_title && (
        <strong>Water Linked DVL </strong>
      )}
      <span style={{ paddingLeft: '0.7em', fontSize: '0.7em' }}>
        {productName} ({version} {variant})
      </span>
    </a>
  );
}
