import { observable, decorate, action, computed } from 'mobx';

const initTrail = () => {
  if (localStorage.getItem('trail')) {
    try {
      const parsed = JSON.parse(localStorage.getItem('trail'));
      if (parsed?.length >= 1 && parsed[0]?.length === 2) {
        return parsed;
      }
    } catch (err) {
      console.log('ERR', err);
      return [];
    }
  }

  return [];
};
const initTrailSeconds = () => {
  if (localStorage.getItem('trailSeconds')) {
    try {
      const parsed = parseInt(localStorage.getItem('trailSeconds'));
      if (parsed > 0) {
        return parsed;
      }
    } catch (err) {
      console.log('ERR', err);
      return 0;
    }
  }

  return 0;
};

class TrailStore {
  trail = initTrail();
  trailSeconds = initTrailSeconds();
  trailFrequency = 3;
  reducedLength = 2500;
  fullResLength = 500;
  wait = null;
  localStorageTimer = null;

  //localStorage.getItem("trail")? JSON.parse(localStorage.getItem("trail"))

  get reducedTrail() {
    //this.trail = this.trail.filter(position=>!!position)
    if (this.trail.length > this.reducedLength) {
      const fullResolution = this.trail.slice(0, this.fullResLength);
      const ratio = Math.ceil(this.trail.length / this.reducedLength);
      const reducedResolution = this.trail.slice(this.fullResLength).filter(function (value, index, Arr) {
        return index % ratio === 0;
      });

      const reducedTrail = [...fullResolution, ...reducedResolution];
      return reducedTrail;
    }

    return this.trail;
  }

  resetTrail() {
    if (this.trail.length) {
      this.trail.length = 0;
    }
    localStorage.setItem('trail', '[]');
  }

  addPostition(position) {
    if (!this.trailSeconds || this.wait || !position || !position.length || position.length !== 3) {
      return;
    } else {
      this.wait = setTimeout(() => {
        this.wait = null;
      }, 1000 / this.trailFrequency);

      const xyPos = [parseFloat(position[0].toFixed(3)), parseFloat(position[1].toFixed(3))];

      this.trail.unshift(xyPos);

      if (this.trail.length > this.trailSeconds * this.trailFrequency) {
        this.trail.length = this.trailSeconds * this.trailFrequency;
      }

      if (!this.localStorageTimer) {
        this.localStorageTimer = setTimeout(() => {
          localStorage.setItem('trail', JSON.stringify(this.trail));
          this.localStorageTimer = null;
        }, 2000);
      }
    }
  }
  setTrailLength(trailSeconds) {
    this.trailSeconds = trailSeconds;
    localStorage.setItem('trailSeconds', trailSeconds);
  }
}

decorate(TrailStore, {
  trail: observable,
  trailSeconds: observable,
  reducedTrail: computed,
  addPostition: action,
  setTrailLength: action.bound,
  resetTrail: action.bound,
});

export default TrailStore;
