import { observable, decorate, action } from 'mobx'
import axios from 'axios'

class FusionStore {
    // Observable values
    mode = "Idle"
    connected = false
    ip_address = "Unknown"

    positionLocal = [0,0,0]
    positionLocalStd = 0
    velocity = [0,0,0]
    velocityStd = 0
    rollPitchYaw = [0,0,0]


    // Fetching state
    loadSuccess = false
    isLoading = true
    loadingError = ""
    isRunning = false

    fetch() {
        if (!this.isRunning) { return false }
        this.isLoading = true
        axios.get('/api/v1/fusion/status')
        .then(response => {
            this.loadSuccess = true
            this.isLoading = false
            this.loadingError = ""

            this.mode = response.data["mode"]
            this.connected = response.data["fusion_connected"]
            this.ip_address = response.data["ip_address"]
            setTimeout(() => this.fetch(), 200)  // 200ms seems like a good trade-off between CPU usage and update speed
        })
        .catch((e) => {
            this.loadSuccess = false
            this.isLoading = false
            this.loadingError = e.toString()

            this.mode = "Idle"
            this.connected = false

            console.log("error", e)
            setTimeout(() => this.fetch(), 1000)
        })
    }
    
    setPositionLocalFromWs(data) {
        this.positionLocal = data
    }
    setPositionLocalStdFromWs(data) {
        this.positionLocalStd = data
    }
    setVelocityFromWs(data) {
        this.velocity = data
    }
    setVelocityStdFromWs(data) {
        this.velocityStd = data
    }
    setRollPitchYawFromWs(data) {
        this.rollPitchYaw = data
    }

    startFetching() {
        if (this.isRunning) {
            console.log("already running")
            return false
        }
        this.isRunning = true
        this.fetch()
    }
    stopFetching() {
        console.log("stopping")
        this.isRunning = false
    }
}

decorate(FusionStore, {
    positionLocal: observable,
    positionLocalStd: observable,
    velocity: observable,
    velocityStd: observable,
    rollPitchYaw: observable,
    mode: observable,
    connected: observable,
    ip_address: observable,
    setPositionFromWs:action,
    setPositionStdFromWs:action,
    setVelocityFromWs:action,
    setVelocityStdFromWs:action
})

export default FusionStore
