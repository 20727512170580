function formatDecimals(val, digits=2) {
    val = parseFloat(val)
    return val.toLocaleString(navigator.language, {minimumFractionDigits: digits, maximumFractionDigits: digits})
}

function RoundDecimalPlaces(val, digits=2) {
    let pow = Math.pow(10, digits)
    return Math.round(val * pow) / pow
}

function GetArrayIndexOrFallback(arr, idx, fallback) {
    if (arr === undefined) {
        return fallback
    }
    if (arr[idx] === undefined) {
        return fallback
    }
    return arr[idx]
}

function IsDefinedFinite(obj, name) {
    if (!(name in obj)) {
        // Don't exist in obj
        return false;
    }
    if (typeof obj[name] !== 'number') {
        // Not a number
        return false;
    }
    // Is defined?
    return isFinite(obj[name])
}

const NoValue = -1e6

export { formatDecimals, RoundDecimalPlaces, GetArrayIndexOrFallback, IsDefinedFinite, NoValue }
