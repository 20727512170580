import React from 'react';
import { Segment, Divider } from 'semantic-ui-react';
import { ForceRangeModeSetting } from '../Components/RangeMode';
import { PeriodicCyclingSetting } from '../Components/PeriodicCycling';
import StatusView from '../Components/Debug/StatusView';
//import Ekkolodd from '../Components/Debug/Ekkolodd';
//import Rotation from '../Components/Debug/AhrsRotation';
//import TempShutdownSetting from '../Components/Debug/DebugTempShutdown';

function Debug() {
    return (
        <Segment attached>
            <Divider horizontal>Debug</Divider>

            <ForceRangeModeSetting />

            <PeriodicCyclingSetting />

            <Divider horizontal>Status</Divider>
            <StatusView detailed={true} collapsing={true} />

            {/* <Divider horizontal>[Dev] Temperature shutdown</Divider>
            <TempShutdownSetting /> */}

            {/* <Divider horizontal>[Dev] Rotation view</Divider>
            <Rotation /> */}

            {/* <Divider horizontal>[Dev] Echo sounder view</Divider>
            <Ekkolodd /> */}
        </Segment>
    );
}

export default Debug;
