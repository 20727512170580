import React from 'react';
import { Segment, Header, Grid, Icon, Table, Divider } from 'semantic-ui-react'
import { observer } from 'mobx-react'
import CorrelatorGraph from '../Components/CorrelatorGraph';
import { useStores } from '../Stores/use-stores'
import { formatDecimals } from '../lib/format';
import { PropTypes } from 'prop-types';
import { HelpPopup } from '../Components/HelpText';
import { LEDButton } from '../Components/LEDButton';
import {SignalStrengthIcon, NoiseStrengthIllustration} from '../Components/SignalStrength';
import StatusView from '../Components/Debug/StatusView';
import { ShowRangeModeURIIfNotAuto } from '../Components/RangeMode'
import { ShowIfPeriodicCyclingIsDisabled } from '../Components/PeriodicCycling'


const VelocityDetail = observer(() => {
    let store = useStores()
    const velocityStore = store.velocityStore

    return (<VelocityDetailsInternal speed={velocityStore.speed} direction={velocityStore.direction} fps={velocityStore.fps}/>)
})

const ValidIcon = ({is_valid}) => {
    if (is_valid) {
        return (
            <Icon title="Valid" color="green" name="check circle"/>
        )
    }
    return (
        <Icon title="Not valid" color="red" name="dont"/>
    )
}

const VelocityDetailsInternal = observer(() => {
    const stores = useStores()
    const store = stores.velocityStore
    return (
    <Table basic="very" columns={3}>
      <Table.Body>
        <Table.Row>
        <Table.Cell>Altitude</Table.Cell>
        <Table.Cell textAlign="right"><strong>{formatDecimals(store.altitude, 2)}</strong></Table.Cell>
        <Table.Cell>m <ShowRangeModeURIIfNotAuto/></Table.Cell>
        </Table.Row>

        <Table.Row>
        <Table.Cell>Velocity</Table.Cell>
        <Table.Cell textAlign="right"><strong>{formatDecimals(store.speed, 3)}</strong></Table.Cell>
        <Table.Cell>m/s</Table.Cell>
        </Table.Row>


        <Table.Row>
        <Table.Cell>Vx</Table.Cell>
        <Table.Cell textAlign="right"><strong>{formatDecimals(store.vx, 3)}</strong></Table.Cell>
        <Table.Cell>m/s</Table.Cell>
        </Table.Row>

        <Table.Row>
        <Table.Cell>Vy</Table.Cell>
        <Table.Cell textAlign="right"><strong>{formatDecimals(store.vy, 3)}</strong></Table.Cell>
        <Table.Cell>m/s</Table.Cell>
        </Table.Row>

        <Table.Row>
        <Table.Cell>Vz</Table.Cell>
        <Table.Cell textAlign="right"><strong>{formatDecimals(store.vz, 3)}</strong></Table.Cell>
        <Table.Cell>m/s</Table.Cell>
        </Table.Row>

        <Table.Row>
        <Table.Cell>Figure of merit</Table.Cell>
        <Table.Cell textAlign="right"><strong>{formatDecimals(store.fom, 4)}</strong></Table.Cell>
        <Table.Cell>m/s <HelpPopup text="A measure of the possible error in the calculated velocity"/> </Table.Cell>
        </Table.Row>

        <Table.Row>
        <Table.Cell>Ping rate</Table.Cell>
        <Table.Cell textAlign="right"><strong>{formatDecimals(store.fps, 0)}</strong></Table.Cell>
        <Table.Cell>Hz <HelpPopup text="Rate at which velocity data is being outputted (depends upon altitude). May be slightly slower when viewing diagnostics"/></Table.Cell>
        </Table.Row>

        <Table.Row>
        <Table.Cell>Velocity Valid</Table.Cell>
        <Table.Cell textAlign="right"><ValidIcon is_valid={store.velocity_valid}/></Table.Cell>
        <Table.Cell><ShowIfPeriodicCyclingIsDisabled/></Table.Cell>
        </Table.Row>

      </Table.Body>
    </Table>
    )
  })


const TransducerDetail = ({data}) => {
    return (
        <Table.Row>
        <Table.Cell>{data.id+1}</Table.Cell>
        <Table.Cell>
            <strong>{data.velocity > 0 && (<span style={{color:"#fff"}}>+</span>)}{formatDecimals(data.velocity, 2)}</strong>&nbsp;m/s
        </Table.Cell>
        <Table.Cell>
            <strong>{formatDecimals(data.distance, 2)}</strong>&nbsp;m
        </Table.Cell>
        <Table.Cell>
            <strong>{formatDecimals(data.rssi, 0)}</strong>&nbsp;dBm
            <SignalStrengthIcon rssi={data.rssi}/>
        </Table.Cell>
        <Table.Cell>
            <strong>{formatDecimals(data.nsd, 0)}</strong>&nbsp;dBm
            <NoiseStrengthIllustration nsd={data.nsd}/>
        </Table.Cell>
        <Table.Cell>
            {data.lock ? (
                // Beam valid
                <Icon title="Beam valid" color="green" name="check circle"/>
            ) : (
                // Beam not valid
                <Icon title="Beam not valid" color="red" name="dont"/>
            )}
        </Table.Cell>
        </Table.Row>
    )
}


TransducerDetail.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.number.isRequired,
        velocity: PropTypes.number.isRequired,
        distance: PropTypes.number.isRequired,
        rssi: PropTypes.number.isRequired,
        nsd: PropTypes.number.isRequired,
        lock: PropTypes.bool.isRequired,
    })
}

const TransducerOverview = observer(() => {
    const { velocityStore } = useStores()
    return (
        <Table basic="very" textAlign="right" columns={6}>
        <Table.Header>
        <Table.Row>
            <Table.Cell>No. <HelpPopup text="Transducer number"/></Table.Cell>
            <Table.Cell>Velocity <HelpPopup text="Velocity measured by this transducer"/></Table.Cell>
            <Table.Cell>Distance <HelpPopup text="Distance to bottom from this transducer (along the line parallel to the transducer angle)"/></Table.Cell>
            <Table.Cell>Signal strength <HelpPopup text="Strength of the received acoustic signal (RSSI - Received Signal Strength Indicator)"/></Table.Cell>
            <Table.Cell>Noise <HelpPopup text="Measurement of interference from other acoustic transmitters in the frequency band in use."/></Table.Cell>
            <Table.Cell>Beam Valid <HelpPopup text="Whether the received acoustic signal received by this transducer was successfully decoded"/></Table.Cell>

        </Table.Row>
        </Table.Header>
          <Table.Body>
              {velocityStore.transducers.map(d => (
                <TransducerDetail key={d.id} data={d}/>
              ))}

          </Table.Body>
        </Table>
    )
})

function Diagnostic() {
    return (
        <Segment>

            <Divider horizontal>Diagnostic</Divider>

            <Grid verticalAlign="middle" divided stackable columns={2}>

            <Grid.Column textAlign="center" width="4">
            <Header as="h5">Doppler velocity</Header>
            <VelocityDetail/>
            </Grid.Column>

            <Grid.Column textAlign="center" width="12">
            <Header as="h5">Transducer details</Header>
            <TransducerOverview/>
            </Grid.Column>

            </Grid>

            <Divider horizontal>Acoustic signal view</Divider>

            <CorrelatorGraph defYMax={0} defYMin={-150}  labelX={"Distance [m]"} labelY="Signal strength [dBm]"/>

            <Divider horizontal>Spectrum</Divider>
            <CorrelatorGraph url={"/api/spectrum"} defYMax={-50} defYMin={-150} labelX={"Frequency [kHz]"} labelY={"Spectral density [dBm]"}/>

            <Divider horizontal className="divider-spacing">Hardware</Divider>
            <p>Verify active connection to DVL by clicking by flashing the LED.</p>
            <LEDButton/>

            <Divider horizontal>Status</Divider>

            <StatusView/>
          </Segment>
      )
}

export default Diagnostic
