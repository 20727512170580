import React from 'react';
import { PropTypes } from 'prop-types';

class DopplerVerticalDirection extends React.Component {
    /*

    Device coordinates

    ^ x
    |
    |  angle positive from x towards y
    |
    ------> y


    Screen coordinates

    ------> x
    |
    |  angle positve from x towards y
    |
    v y

    From device to screen: subtract 90 degrees to angle

    */
      render() {
        const width = 220
        const height = 240
        const radius = 50
        const maxSpeed = 0.5  // The speed that is needed to show full direction arrow (speeds above this is clamped)
        const stationarySpeed = 0.1 // Speeds below this is shown as a dot. This is to avoid arrow looking erratic.
        const { speed } = this.props
        const direction = speed > 0 ? 90 : -90
        const speedValue = Math.abs(speed)

        const rad = direction / 180.0 * Math.PI;
        const clampSpeed = Math.min(speedValue, maxSpeed)
        const circumferenceStroke = 10
        const arrowHeadSize = 8  // This should probably be based on the marker drawing below, now it is just set to a value that looks good
        const x = ((radius-circumferenceStroke/2-arrowHeadSize)*clampSpeed/maxSpeed)*Math.cos(rad)
        const y = ((radius-circumferenceStroke/2-arrowHeadSize)*clampSpeed/maxSpeed)*Math.sin(rad)
        const velocityValidColor = "#4292c6" //velocity_valid ? '#4292c6' : "#fa1f1f"
        return (
          <div title="Vertical speed. Arrow show direction of travel. Length of arrow illustrates speed. Up on the illustation means altitude is increasing.">
          <svg width={width} height={height} viewBox="-60 -60 120 140">
          <defs>
            <marker id='headVert' orient='auto' markerWidth='2' markerHeight='4'
                refX='0.1' refY='2'>
              <path d='M0,0 V4 L2,2 Z' fill={velocityValidColor} fillOpacity="0.8"/>
            </marker>
          </defs>
            {/* Surrounding box: */}
            <rect x={-20} y={-60} width={40} height={120} stroke="lightgray" strokeOpacity="0.5" strokeWidth={circumferenceStroke} fill="none" />
            {/* Speed arrow or dot if slow movement: */}
            {speedValue > stationarySpeed ? (
              <line x1={0} y1={0} x2={x} y2={y}
              markerEnd='url(#headVert)'
              strokeWidth='4' fill='none' stroke={velocityValidColor} strokeOpacity="0.8"
              />
          ) : (
              <line x1={-10} y1={0} x2={10} y2={0}
                stroke={velocityValidColor}
                strokeOpacity="0.8"
                strokeWidth='4'
              />
            )}
          </svg>
          </div>
        )
      }
}


DopplerVerticalDirection.propTypes = {
  speed: PropTypes.number.isRequired,
}

export default DopplerVerticalDirection
